import { useEffect, useState, useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import Navbar from "./navbar";
import ReCAPTCHA from "react-google-recaptcha";
import IconExpand from "./assets/expand-arrow-30.png";
import IconWarnig from "./assets/exclamation-triangle-fill.png";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ApiUrl } from "./ApiUrl";
import Loader from "./loader";

import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import { externalUsers, internalUsers } from "./constants/userTypes";

const ViewClientSubmission = ({ isDarkMode, setIsDarkMode }) => {
  const validationSchema = Yup.object().shape({
    id: Yup.number().required("ID is required"),
    // name: Yup.string().required('Name is required'),
    // dealer: Yup.number().required('Dealer is required'),
    // dealershipName: Yup.string().required('Dealership name is required'),
    // week: Yup.number().required('Week is required'),
    // package: Yup.string().required('Package is required'),
    // sales_representative: Yup.string().required('Sales Representative is required'),
    event_title: Yup.string().required("Event Title is required"),
    // digital: Yup.string().oneOf(['Yes', 'No']).required('Digital must be Yes or No'),
    // last_sale_date: Yup.date().required('Last Sale Date is required'),
    postal_quantity: Yup.number().required("Postal Quantity is required"),
    // event_dates: Yup.string().nullable(true),//.required('Event Dates are required'),
    // days: Yup.number().required('Days is required'),
    // first_day: Yup.date().required('First Day is required'),
    target_age: Yup.string().required("Target Age is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    regional_codes: Yup.number().required("Regional Codes is required"),
    // to_order: Yup.string().required('To Order is required'),
    // number_of_postal_sends: Yup.number().required('Number of Postal Sends is required'),
    // access_number: Yup.number().required('Access Number is required'),
    // download_key: Yup.string().required('Download Key is required'),
    targeted_income: Yup.number().required("Targeted Income is required"),
    // sale_title: Yup.string().required('Sale Title is required'),
    featured_vehicle: Yup.string().required("Featured Vehicle is required"),
    your_featured_vehicle: Yup.string().required(
      "Your Featured Vehicle is required"
    ),
    visual_language: Yup.string().required("Visual Language is required"),
    targeted_territory: Yup.string().required("Targeted Territory is required"),
    // receptionist: Yup.string().required('Receptionist is required'),
    greets_customers: Yup.string().required("Greets Customers is required"),
    assignment_by_seller: Yup.string().required(
      "Assignment by Seller is required"
    ),
    new_purchase_or_lease: Yup.string().required(
      "New Purchase or Lease is required"
    ),
    // opening_hours: Yup.number().required('Opening Hours is required'),
    appointments_per_time_slot: Yup.number().required(
      "Appointments per Time Slot is required"
    ),
    appointment_time_slots: Yup.number().required(
      "Appointment Time Slots is required"
    ),
    balloons_colors: Yup.string().required("Balloons Colors is required"),
    balloons_quantity: Yup.number().required("Balloons Quantity is required"),
    representatives: Yup.string().required("Representatives are required"),
    contact_list_phone: Yup.string().required("Contact List Phone is required"),
    contact_list_email: Yup.string()
      .email("Invalid email")
      .required("Contact List Email is required"),
    contact_list_name: Yup.string().required("Contact List Name is required"),
    contact_tp_phone: Yup.string().required("Contact TP Phone is required"),
    contact_tp_email: Yup.string()
      .email("Invalid email")
      .required("Contact TP Email is required"),
    contact_tp_name: Yup.string().required("Contact TP Name is required"),
    // comments: Yup.string().required('Comments are required'),
    promotions: Yup.string().required("Promotions are required"),
    directors: Yup.string().required("Directors are required"),
    prioriti_reason: Yup.string()
      .when("models_or_years_selection", {
        is: "Yes",
        then: (schema) => schema.required("Priority Reason is required"), // Yup.string().required('Modifications Instructions is required'),
        otherwise: (schema) => schema.optional(),
      })
      .nullable(true),
    models_or_years_selection: Yup.string().required(
      "Models or Years Selection is required"
    ),
    modifications_instructions: Yup.string().nullable(true), //.required('Modifications Instructions is required'),
    receptionist: Yup.string().nullable(true),
    // dealership_employee: Yup.string().nullable(true),
    additional_information: Yup.string().nullable(true),
    // recapchaVal: Yup.string().required("Fill recaptcha field!"),
    // files: Yup.array()
    //   .of(
    //     Yup.mixed().test(
    //       "fileSize",
    //       "File size exceeds the maximum limit (5 MB)",
    //       (value) => {
    //         return !value || (value && value.size <= 5 * 1024 * 1024); // 5 MB in bytes
    //       }
    //     )
    //     // .test('fileType', 'Invalid file type. Allowed types are PDF, JPEG, DOCX, CSV, XLSX and PNG.', (value) => {
    //     //     return !value || (value && ['application/pdf', 'image/jpeg', 'image/png', 'image/xlsx', 'image/docx', 'image/csv'].includes(value.type));
    //     // })
    //   )
    //   // .nullable(true)
    //   .min(1, "At least one file is required"),
  });
  const [accordianShow, setAccordianShow] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const [clientData, setClientData] = useState();
  const [formValues, setFormValues] = useState(null);
  const [oldFiles, setOldFiles] = useState(null);
  const currentDate = new Date();
  // useLocation gives you access to the current URL location
  const location = useLocation();
  const { language } = useLanguage();
  // Check if the token exists in local storage
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(null);
  const userType = localStorage.getItem("userType");
  //Files Drop
  // const onDrop = useCallback(acceptedFiles => {
  //     // Do something with the files
  // }, [])
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  // Get the value of the 'reset_token' query parameter
  const urlPath = window.location.href;
  const queryParams = new URLSearchParams(location.search);
  const client_id = queryParams.get("id");
  const parent_id =
    urlPath && urlPath.includes("parent_id")
      ? queryParams.get("parent_id")
      : null;

  const [isLoading, setIsLoading] = useState(false);

  const [expire, setExpire] = useState(null);

  useEffect(() => {
    // If the token exists, redirect to "/event-portal"
    if (!token) {
      navigate("/", { replace: true });
      // navigate('/?parent_id=' + parent_id);
    } else {
      //     console.log('usertype clientForm', userType)
      if (externalUsers.includes(userType)) {
        // navigate('/client-form')
      } else if (internalUsers.includes(userType)) {
        navigate("/event-portal", { replace: true });
      } else {
        toast.error("Something went wrong!");
        navigate("/", { replace: true });
      }
    }
  }, [token, userType]);

  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/get-client-submittions-data?id=" + client_id,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log('Res:', res);
        const data = await res.json();
        if (data.status === "success") {
          setExpire(
            urlPath &&
              urlPath.includes("parent_id") &&
              data.client_submittions_data[0].first_day
              ? currentDate <
                new Date(data.client_submittions_data[0].first_day)
                ? false
                : true
              : false
          );
          // console.log(data.client_form_data)
          if (data.submition_check && data.submition_check.length === 0) {
            setClientData(data.client_submittions_data[0]);
            const data2 = {
              ...data.parent_form_data[0],
              id: data.client_submittions_data[0]?.id,
              parent_id: data.client_submittions_data[0]?.parent_id,
              additional_information:
                data.client_submittions_data[0]?.additional_information,
              client_id: data.client_submittions_data[0]?.client_id,
              dealershipName: data.client_submittions_data[0]?.dealershipName,
              dealership_employee:
                data.client_submittions_data[0]?.dealership_employee,
              due_date: data.client_submittions_data[0]?.due_date,
              event_title: data.client_submittions_data[0]?.event_title,
              invitation_accepted:
                data.client_submittions_data[0]?.invitation_accepted,
              postal_quantity: data.client_submittions_data[0]?.postal_quantity,
              promotions_to_highlight:
                data.client_submittions_data[0]?.promotions_to_highlight,
              submitted_on: data.client_submittions_data[0]?.submitted_on,
              your_featured_vehicle:
                data.client_submittions_data[0]?.your_featured_vehicle,
            };
            if (data2 !== undefined && data2 != null) {
              for (const key in data2) {
                if (data2[key] === null || data2[key] === "null") {
                  data2[key] = "";
                }
              }
            }
            setFormValues({
              ...data2,
              files: [],
              recapchaVal: "",
            });
          } else {
            setClientData(data.client_submittions_data[0]);
            const data2 = data.client_submittions_data[0];
            if (
              data.client_submittions_data[0] !== undefined &&
              data.client_submittions_data[0] != null
            ) {
              for (const key in data2) {
                if (data2[key] === null || data2[key] === "null") {
                  data2[key] = "";
                }
              }
              // console.log(data2);
            }

            setFormValues({
              ...data2,
              files: [],
              recapchaVal: "",
            });
          }
          setOldFiles(data.docs_list);
          // setHistoryData(data.parent_form_data[0]);
          setEditable(
            data.client_submittions_data[0].first_day
              ? currentDate <
                new Date(data.client_submittions_data[0].first_day)
                ? true
                : false
              : false
          );

          setLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    };

    if (urlPath && !urlPath.includes("parent_id")) fetchClientData();
  }, [location]);
  useEffect(() => {
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/get-client-submittions-data-email-link",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              parent_id: parent_id,
            }),
          }
        );
        // console.log('Res:', res);
        const data = await res.json();
        if (data.status === "success") {
          setExpire(
            urlPath &&
              urlPath.includes("parent_id") &&
              data.client_submittions_data[0].first_day
              ? currentDate <
                new Date(data.client_submittions_data[0].first_day)
                ? false
                : true
              : false
          );
          // console.log(data.client_form_data)
          if (data.submition_check && data.submition_check.length === 0) {
            setClientData(data.client_submittions_data[0]);
            const data2 = {
              ...data.parent_form_data[0],
              id: data.client_submittions_data[0]?.id,
              parent_id: data.client_submittions_data[0]?.parent_id,
              additional_information:
                data.client_submittions_data[0]?.additional_information,
              client_id: data.client_submittions_data[0]?.client_id,
              dealershipName: data.client_submittions_data[0]?.dealershipName,
              dealership_employee:
                data.client_submittions_data[0]?.dealership_employee,
              due_date: data.client_submittions_data[0]?.due_date,
              event_title: data.client_submittions_data[0]?.event_title,
              invitation_accepted:
                data.client_submittions_data[0]?.invitation_accepted,
              postal_quantity: data.client_submittions_data[0]?.postal_quantity,
              promotions_to_highlight:
                data.client_submittions_data[0]?.promotions_to_highlight,
              submitted_on: data.client_submittions_data[0]?.submitted_on,
              your_featured_vehicle:
                data.client_submittions_data[0]?.your_featured_vehicle,
            };
            if (data2 !== undefined && data2 != null) {
              for (const key in data2) {
                if (data2[key] === null || data2[key] === "null") {
                  data2[key] = "";
                }
              }
            }
            setFormValues({
              ...data2,
              files: [],
              recapchaVal: "",
            });
          } else {
            setClientData(data.client_submittions_data[0]);
            const data2 = data.client_submittions_data[0];
            if (
              data.client_submittions_data[0] !== undefined &&
              data.client_submittions_data[0] != null
            ) {
              for (const key in data2) {
                if (data2[key] === null || data2[key] === "null") {
                  data2[key] = "";
                }
              }
              // console.log(data2);
            }

            setFormValues({
              ...data2,
              files: [],
              recapchaVal: "",
            });
          }
          setOldFiles(data.docs_list);
          // setHistoryData(data.parent_form_data[0]);
          setEditable(
            data.client_submittions_data[0].first_day
              ? currentDate <
                new Date(data.client_submittions_data[0].first_day)
                ? true
                : false
              : false
          );

          setLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong!");
      }
    };
    if (urlPath && urlPath.includes("parent_id")) fetchClientData();
  }, [location]);

  const handleSubmit = async (values, { errors }) => {
    const formData = new FormData();
    // Append each file to the FormData object
    values.files.forEach((file, index) => {
      formData.append(`file${index + 1}`, file);
    });
    Object.keys(values).forEach((key) => {
      if (
        key === "submitted_on" &&
        (values[key] === null || values[key] === "")
      ) {
        formData.append("invittion_id", 1);
      }

      // Exclude the 'files' key as it was handled separately
      if (key !== "files") {
        formData.append(key, values[key]);
      }
      if (key === "recapchaVal") formData.delete(key);
    });

    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/add-childform-data", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      setFormValues(values);

      const data = await res.json();
      if (data.status === "success") {
        if (values.submitted_on === "" || values.submitted_on === null)
          window.history.pushState(
            {
              id: "gallery",
              randomData: window.Math.random(),
            },
            "title",
            "/client-submission?id=" + data.data.id
          );
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
      // console.log(error)
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="splash-screen">
          <Loader />
        </div>
      )}
      <div className="client-form" style={{ minHeight: "90vh" }}>
        <Navbar
          setToken={setToken}
          token={token}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
        />
        {!loading && clientData && (
          <div className="client-form-parent">
            <button
              type="button"
              class="btn btn-light"
              onClick={() => navigate("/client-dashboard")}
            >
              <i class="bi bi-arrow-left-circle"></i>{" "}
              {translations.viewClientForm.formListBtn[language]}
            </button>
            {!expire ? (
              <Formik
                initialValues={formValues}
                // initialValues={{
                //     id: 0,
                //     name: "",
                //     dealer: 0,
                //     week: 0,
                //     package: "",
                //     sales_representative: "",
                //     event_title: "",
                //     digital: "",
                //     last_sale_date: "",
                //     postal_quantity: 0,
                //     event_dates: "",
                //     days: 0,
                //     first_day: "",
                //     target_age: "",
                //     postal_code: 0,
                //     regional_codes: 0,
                //     to_order: "",
                //     number_of_postal_sends: 0,
                //     access_number: 0,
                //     download_key: "",
                //     targeted_income: 0,
                //     sale_title: "",
                //     featured_vehicle: "",
                //     favorites: "",
                //     visual_language: "",
                //     targeted_territory: "",
                //     receptionist: "",
                //     greets_customers: "",
                //     assignment_by_seller: "",
                //     new_purchase_or_lease: "",
                //     opening_hours: 0,
                //     appointments_per_time_slot: 0,
                //     appointment_time_slots: 0,
                //     balloons_colors: "",
                //     balloons_quantity: 0,
                //     representatives: "",
                //     contact_list_phone: "",
                //     contact_list_email: "",
                //     contact_list_name: "",
                //     contact_tp_phone: "",
                //     contact_tp_email: "",
                //     contact_tp_name: "",
                //     comments: "",
                //     promotions: "",
                //     directors: "",
                //     prioriti_reason: "",
                //     models_or_years_selection: "",
                //     modifications_instructions: "",
                // }}
                validationSchema={Yup.object().shape({
                  // recapchaVal: Yup.string().required("Fill recaptcha field!"),
                })}
                onSubmit={handleSubmit}
              >
                {({ errors, values, setValues, setFieldValue }) => (
                  <Form className="cform">
                    {/* {()()} */}
                    <div className="client-notification">
                      {translations.viewClientForm.message1[language]}
                      <strong> {values.due_date}</strong>
                    </div>
                    <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                      {translations.viewClientForm.generalInfo.title[language]}
                    </h2>
                    <div className="client-form-row">
                      <div className="info-column">
                        <label htmlFor="id" className="form-label">
                          ID
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="id"
                          disabled
                          style={{
                            backgroundColor: "#ced4da66",

                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="id"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      <div className="info-column">
                        <label htmlFor="dealershipName" className="form-label">
                          {translations.commonWords.dealer[language]}
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="dealershipName"
                          disabled
                          style={{
                            backgroundColor: "#ced4da66",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="dealershipName"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      <div className="info-column">
                        <label
                          htmlFor="sales_representative"
                          className="form-label"
                        >
                          {
                            translations.forms.formFields.teamSection
                              .representative[language]
                          }
                        </label>
                        <br />
                        <Field
                          type="text"
                          name="sales_representative"
                          disabled
                          style={{
                            backgroundColor: "#ced4da66",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="sales_representative"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>

                    <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                      Dates
                    </h2>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div /*classname="mb-3"*/>
                          <label htmlFor="first_day" title="">
                            {
                              translations.forms.formFields.datesSection
                                .first_day[language]
                            }
                          </label>
                          <Field
                            // value={
                            //   values.first_day
                            //     ? new Date(values.first_day)
                            //         .toISOString()
                            //         .split("T")[0]
                            //     : ""
                            // }
                            value={
                              values.first_day
                                ? values.first_day.split(" ")[0]
                                : ""
                            }
                            type="text"
                            name="first_day"
                            disabled
                            style={{
                              backgroundColor: "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="first_day"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div className="mb-3">
                          <label
                            htmlFor="days"
                            data-bs-toggle="tooltip"
                            title="" /*class="form-label"*/
                          >
                            {
                              translations.forms.formFields.datesSection
                                .totalNumDays[language]
                            }
                          </label>
                          <Field
                            as="select"
                            name="days"
                            class={"form-control"}
                            aria-label="Default select example"
                            id="nb_day_1"
                            disabled
                            style={{
                              backgroundColor: "#ced4da66",

                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          >
                            <option value="" selected="">
                              {
                                translations.forms.formFields.datesSection
                                  .numDays[language]
                              }
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                          </Field>
                          <ErrorMessage
                            name="days"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div /*class="mb-3"*/>
                          <label
                            htmlFor="event_dates"
                            data-bs-toggle="tooltip"
                            title="" /*class="form-label"*/
                          >
                            {
                              translations.viewClientForm.dates.eventDays[
                                language
                              ]
                            }
                          </label>
                          <Field
                            as="textarea"
                            name="event_dates"
                            /*class="form-control"*/ id="date_event_1"
                            rows="2"
                            disabled
                            style={{
                              backgroundColor: "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="event_dates"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                      {translations.viewClientForm.callCenter.title[language]}
                    </h2>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="appointment_time_slots"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {
                                translations.viewClientForm.callCenter
                                  .durationOfSlots[language]
                              }
                            </label>

                            <span className="client-tooltiptext">
                              {
                                translations.viewClientForm.callCenter.tip1[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            as="select"
                            name="appointment_time_slots"
                            class={` ${
                              editable
                                ? isDarkMode
                                  ? "custom-select form-select"
                                  : "form-select"
                                : "form-control"
                            }`}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            aria-label="Default select example"
                            id="plage-hor-des-rdv"
                            fdprocessedid="1riyds"
                            disabled={!editable}
                          >
                            <option
                              value=""
                              selected=""
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations.dropdown.select[language]}
                            </option>
                            <option
                              value="30"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              30
                            </option>
                            <option
                              value="60"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              60
                            </option>
                            {/* <option
                              value="90"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              90
                            </option> */}
                            <option
                              value="120"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              120
                            </option>
                          </Field>
                          <ErrorMessage
                            name="appointment_time_slots"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>

                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="appointments_per_time_slot"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {
                                translations.forms.formFields.salesInfoSection
                                  .appointmentsPTS[language]
                              }
                            </label>

                            <span className="client-tooltiptext">
                              {
                                translations.forms.tooltip.appointmentsPTS[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            as="select"
                            name="appointments_per_time_slot"
                            class={` ${
                              editable
                                ? isDarkMode
                                  ? "custom-select form-select"
                                  : "form-select"
                                : "form-control"
                            }`}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            aria-label="Default select example"
                            id="rdv-par-plage-hor"
                            fdprocessedid="t0suu"
                            disabled={!editable}
                          >
                            <option
                              value=""
                              selected=""
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations.dropdown.select[language]}
                            </option>
                            <option
                              value="1"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              1
                            </option>
                            <option
                              value="2"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              2
                            </option>
                            <option
                              value="3"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              3
                            </option>
                            <option
                              value="4"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              4
                            </option>
                            <option
                              value="5"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              5
                            </option>
                            <option
                              value="6"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              6
                            </option>
                            <option
                              value="7"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              7
                            </option>
                            <option
                              value="8"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              8
                            </option>
                            <option
                              value="9"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              9
                            </option>
                            <option
                              value="10"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              10
                            </option>
                            <option
                              value="11"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              11
                            </option>
                            <option
                              value="12"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              12
                            </option>
                            <option
                              value="13"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              13
                            </option>
                            <option
                              value="14"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              14
                            </option>
                            <option
                              value="15"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              15
                            </option>
                            <option
                              value="16"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              16
                            </option>
                            <option
                              value="17"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              17
                            </option>
                            <option
                              value="18"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              18
                            </option>
                            <option
                              value="19"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              19
                            </option>
                            <option
                              value="20"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              20
                            </option>
                          </Field>
                          <ErrorMessage
                            name="appointments_per_time_slot"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="opening_hours"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {
                                translations.forms.formFields.salesInfoSection
                                  .openingHoursWLA[language]
                              }
                            </label>

                            <span className="client-tooltiptext">
                              {
                                translations.forms.tooltip.openingHoursWLA[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            name="opening_hours"
                            type="text"
                            /*class="form-control"*/ id="heures-ouverture"
                            fdprocessedid="sprd8c"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="opening_hours"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="greets_customers"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {
                                translations.viewClientForm.callCenter
                                  .guidingClients[language]
                              }
                            </label>

                            <span className="client-tooltiptext">
                              {
                                translations.viewClientForm.callCenter.tip4[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            as="select"
                            name="greets_customers"
                            class={` ${
                              editable
                                ? isDarkMode
                                  ? "custom-select form-select"
                                  : "form-select"
                                : "form-control"
                            }`}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            aria-label="Default select example"
                            id="accueille-les-clients"
                            fdprocessedid="k8itlb"
                            disabled={!editable}
                          >
                            <option
                              value=""
                              selected=""
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations.dropdown.select[language]}
                            </option>
                            <option
                              value="Event Director"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations["dropdown"].eventDirector[language]}
                            </option>
                            <option
                              value="Dealership Employee"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {
                                translations["dropdown"].dealershipEmployee[
                                  language
                                ]
                              }
                            </option>
                            <option
                              value="Reception"
                              style={{
                                background: isDarkMode ? "#1B1F22" : "",
                                color: isDarkMode ? "#ffffff" : "",
                              }}
                            >
                              {translations["dropdown"].Reception[language]}
                            </option>
                          </Field>
                          <ErrorMessage
                            name="greets_customers"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="receptionist"
                              data-bs-toggle="tooltip" /*class="form-label form-label-sm"*/
                            >
                              {
                                translations.viewClientForm.callCenter
                                  .dealershipEmployee[language]
                              }
                            </label>

                            <span className="client-tooltiptext">
                              {
                                translations.viewClientForm.callCenter.tip5[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            name="receptionist"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="receptionist"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div className="client-tooltip">
                          <label htmlFor="new_purchase_or_lease">
                            {
                              translations.forms.formFields.salesInfoSection
                                .buyerOfUsedVehicles[language]
                            }
                          </label>
                          <span className="client-tooltiptext">
                            {
                              translations.forms.tooltip.buyerOfUsedVehicles[
                                language
                              ]
                            }
                          </span>
                        </div>

                        <br />
                        <Field
                          as="select"
                          name="new_purchase_or_lease"
                          style={{
                            backgroundColor: editable
                              ? isDarkMode
                                ? "transparent"
                                : ""
                              : isDarkMode
                              ? "#ced4da66"
                              : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          class={` ${
                            editable
                              ? isDarkMode
                                ? "custom-select form-select"
                                : "form-select"
                              : "form-control"
                          }`}
                          aria-label="Default select example"
                          disabled={!editable}
                        >
                          <option
                            value=""
                            selected=""
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {translations.dropdown.select[language]}
                          </option>
                          <option
                            value="Yes"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {translations.dropdown.yes[language]}
                          </option>
                          <option
                            value="No"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {translations.dropdown.no[language]}
                          </option>
                        </Field>
                        <ErrorMessage
                          name="new_purchase_or_lease"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                      <div className="info-column">
                        <div className="client-tooltip">
                          <label htmlFor="assignment_by_seller">
                            {
                              translations.forms.formFields.salesInfoSection
                                .appointmentABS[language]
                            }
                          </label>
                          <span className="client-tooltiptext">
                            {
                              translations.forms.tooltip.appointmentABS[
                                language
                              ]
                            }
                          </span>
                        </div>
                        <br />
                        <Field
                          as="select"
                          name="assignment_by_seller"
                          style={{
                            backgroundColor: editable
                              ? isDarkMode
                                ? "transparent"
                                : ""
                              : isDarkMode
                              ? "#ced4da66"
                              : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          class={` ${
                            editable
                              ? isDarkMode
                                ? "custom-select form-select"
                                : "form-select"
                              : "form-control"
                          }`}
                          aria-label="Default select example"
                          id="ass-rdv"
                          disabled={!editable}
                        >
                          <option
                            value=""
                            selected=""
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {translations.dropdown.select[language]}
                          </option>
                          <option
                            value="Assignment"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {
                              translations.forms.formFields.salesInfoSection
                                .assignment[language]
                            }
                          </option>
                          <option
                            value="No Assignment"
                            style={{
                              background: isDarkMode ? "#1B1F22" : "",
                              color: isDarkMode ? "#ffffff" : "",
                            }}
                          >
                            {
                              translations.forms.formFields.salesInfoSection
                                .noAssignment[language]
                            }
                          </option>
                        </Field>
                        <ErrorMessage
                          name="assignment_by_seller"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                      Contacts
                    </h2>
                    <h5>
                      {
                        translations.forms.formFields.contactSection
                          .themePromos[language]
                      }
                    </h5>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="contact_tp_name"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {translations.commonFields.name[language]}
                            </label>

                            <span className="client-tooltiptext">
                              {
                                translations.forms.tooltip.themePromosName[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            name="contact_tp_name"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="contact_tp_name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="contact_tp_email"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {translations.commonFields.email[language]}
                            </label>

                            <span className="client-tooltiptext">
                              {
                                translations.forms.tooltip.themePromosEmail[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            name="contact_tp_email"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="contact_tp_email"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="contact_tp_phone"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {translations.commonFields.phone[language]}
                            </label>
                            <span className="client-tooltiptext">
                              {
                                translations.forms.tooltip.themePromosPhone[
                                  language
                                ]
                              }
                            </span>
                          </div>

                          <Field
                            name="contact_tp_phone"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="contact_tp_phone"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <h5>
                      {
                        translations.forms.formFields.contactSection.lists[
                          language
                        ]
                      }
                    </h5>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="contact_list_name"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {translations.commonFields.name[language]}
                            </label>

                            <span className="client-tooltiptext">
                              {translations.forms.tooltip.listsName[language]}
                            </span>
                          </div>
                          <Field
                            name="contact_list_name"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="contact_list_name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="contact_list_email"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {translations.commonFields.email[language]}
                            </label>

                            <span className="client-tooltiptext">
                              {translations.forms.tooltip.listsEmail[language]}
                            </span>
                          </div>
                          <Field
                            name="contact_list_email"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="contact_list_email"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="contact_list_phone"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {translations.commonFields.phone[language]}
                            </label>
                            <span className="client-tooltiptext">
                              {translations.forms.tooltip.listsPhone[language]}
                            </span>
                          </div>

                          <Field
                            name="contact_list_phone"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="contact_list_phone"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                      {
                        translations.forms.formFields.teamSection.title[
                          language
                        ]
                      }
                    </h2>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="directors"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {
                                translations.forms.formFields.salesInfoSection
                                  .salesdirector[language]
                              }
                            </label>
                            <span className="client-tooltiptext">
                              {translations.forms.tooltip.directors[language]}
                            </span>
                          </div>
                          <Field
                            name="directors"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="directors"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="info-column">
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="representatives"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {
                                translations.forms.clientFormLabels
                                  .representatives[language]
                              }
                            </label>
                            <span className="client-tooltiptext">
                              {
                                translations.forms.tooltip.representatives[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            as="textarea"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            name="representatives"
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="representatives"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="client-form-row">
                      <div class="info-column">
                        <div class="mb-3">
                          <label
                            for="equipe-directeurs"
                            data-bs-toggle="tooltip"
                            title="Name of the Director(s)"
                            class="form-label"
                          >
                            {
                              translations.forms.formFields.teamSection
                                .responsible_payable[language]
                            }
                          </label>

                          <Field
                            type="text"
                            name="responsible_payable"
                            class="form-control"
                            id="equipe-directeurs"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="responsible_payable"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="info-column"></div>
                    </div>
                    <div>
                      <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                        {translations.viewClientForm.visualInfo.title[language]}{" "}
                      </h2>

                      <div class="client-form-row">
                        <div class="info-column">
                          <div>
                            <div className="client-tooltip">
                              <label
                                htmlFor="event_title"
                                data-bs-toggle="tooltip" /*class="form-label"*/
                              >
                                {
                                  translations.viewClientForm.visualInfo
                                    .eventTitle[language]
                                }{" "}
                              </label>
                              <span class="client-tooltiptext">
                                {
                                  translations.viewClientForm.visualInfo.tip1[
                                    language
                                  ]
                                }
                              </span>
                            </div>
                            <Field
                              name="event_title"
                              type="text"
                              disabled={!editable}
                              style={{
                                backgroundColor: isDarkMode
                                  ? editable
                                    ? "transparent"
                                    : "#ced4da66"
                                  : editable
                                  ? ""
                                  : "#ced4da66",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />
                            <ErrorMessage
                              name="event_title"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div class="info-column">
                          <div>
                            <div className="client-tooltip">
                              <label
                                htmlFor="featured_vehicle"
                                data-bs-toggle="tooltip"
                              >
                                {
                                  translations.viewClientForm.visualInfo
                                    .featuredVehicle[language]
                                }
                              </label>
                              <span class="client-tooltiptext">
                                {
                                  translations.viewClientForm.visualInfo.tip2[
                                    language
                                  ]
                                }
                              </span>
                            </div>
                            <Field
                              name="featured_vehicle"
                              type="text"
                              disabled={!editable}
                              style={{
                                backgroundColor: isDarkMode
                                  ? editable
                                    ? "transparent"
                                    : "#ced4da66"
                                  : editable
                                  ? ""
                                  : "#ced4da66",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />
                            <ErrorMessage
                              name="featured_vehicle"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="client-form-row">
                        <div class="info-column">
                          <div /*class="mb-3"*/>
                            <div className="client-tooltip">
                              <label
                                htmlFor="favorites"
                                data-bs-toggle="tooltip" /*class="form-label"*/
                              >
                                {
                                  translations.viewClientForm.visualInfo
                                    .featuredVehicle2[language]
                                }
                              </label>

                              <span class="client-tooltiptext">
                                {
                                  translations.viewClientForm.visualInfo.tip3[
                                    language
                                  ]
                                }
                              </span>
                            </div>
                            <Field
                              name="favorites"
                              type="text" /*class="form-control"*/
                              disabled={!editable}
                              style={{
                                backgroundColor: isDarkMode
                                  ? editable
                                    ? "transparent"
                                    : "#ced4da66"
                                  : editable
                                  ? ""
                                  : "#ced4da66",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />
                            <ErrorMessage
                              name="favorites"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div class="info-column">
                          <div>
                            <div className="client-tooltip">
                              <label
                                htmlFor="visual_language"
                                data-bs-toggle="tooltip" /*class="form-label"*/
                              >
                                {
                                  translations.viewClientForm.visualInfo
                                    .languageOfVisual[language]
                                }
                              </label>

                              <span class="client-tooltiptext">
                                {
                                  translations.viewClientForm.visualInfo
                                    .languageOfVisual[language]
                                }
                              </span>
                            </div>
                            <Field
                              as="select"
                              name="visual_language"
                              class={` ${
                                editable
                                  ? isDarkMode
                                    ? "custom-select form-select"
                                    : "form-select"
                                  : "form-control"
                              }`}
                              style={{
                                backgroundColor: isDarkMode
                                  ? editable
                                    ? "transparent"
                                    : "#ced4da66"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                              id="langue-des-visuelles"
                              disabled={!editable}
                            >
                              <option
                                vlaue=""
                                selected=""
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {translations.dropdown.select[language]}
                              </option>
                              <option
                                value="French"
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {translations.commonWords.french[language]}
                              </option>
                              <option
                                value="English"
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {translations.commonWords.english[language]}
                              </option>
                              <option
                                value="Bilingual"
                                style={{
                                  background: isDarkMode ? "#1B1F22" : "",
                                  color: isDarkMode ? "#ffffff" : "",
                                }}
                              >
                                {translations.commonWords.bilangual[language]}
                              </option>
                            </Field>
                            <ErrorMessage
                              name="visual_language"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="client-tooltip">
                            <label
                              htmlFor="promotions"
                              data-bs-toggle="tooltip" /*class="form-label"*/
                            >
                              {
                                translations.viewClientForm.visualInfo
                                  .promoHighlights[language]
                              }
                            </label>
                            <span class="client-tooltiptext">
                              {
                                translations.viewClientForm.visualInfo.tip4[
                                  language
                                ]
                              }
                            </span>
                          </div>
                          <Field
                            as="textarea"
                            name="promotions"
                            type="text"
                            /*class="form-control"*/ id="event-promos"
                            rows="2"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="promotions"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                      {translations.viewClientForm.additionalInfo[language]}
                    </h2>
                    <div className="client-form-row">
                      <div className="info-column">
                        <div>
                          <Field
                            as="textarea"
                            disabled={!editable}
                            style={{
                              backgroundColor: isDarkMode
                                ? editable
                                  ? "transparent"
                                  : "#ced4da66"
                                : editable
                                ? ""
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            name="comments"
                            type="text" /*class="form-control"*/
                          />
                          <ErrorMessage
                            name="comments"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    {/* --------------------------------------------- */}

                    <div className="client-accordion">
                      {false && (
                        <div className="client-accordion-item">
                          <h2
                            className="client-accordion-header"
                            id="headingFour"
                            onClick={() => {
                              accordianShow !== 1
                                ? setAccordianShow(1)
                                : setAccordianShow(0);
                            }}
                          >
                            <button
                              className="client-accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFour"
                              aria-expanded="true"
                              aria-controls="collapseFour"
                              fdprocessedid="x8akgr"
                            >
                              <div>
                                <strong>Balloons – Showroom &nbsp;</strong>
                                <span className="client-badge">
                                  <img
                                    src={IconWarnig}
                                    style={{ width: 15 }}
                                    alt="Warning icon"
                                  ></img>{" "}
                                  {
                                    translations.viewClientForm.message2[
                                      language
                                    ]
                                  }
                                </span>
                                {/* ::after */}
                              </div>
                              <img
                                className={
                                  accordianShow === 1 || showAll
                                    ? "arrow arrow-rotate"
                                    : "arrow"
                                }
                                src={IconExpand}
                                alt=""
                              />
                            </button>
                          </h2>
                          {(accordianShow === 1 || showAll) && (
                            <div
                              id="collapseFour"
                              className="client-accordion-collapse"
                              aria-labelledby="headingFour"
                              data-bs-parent="#accordionIfApplicable"
                            >
                              <div className="client-accordion-body">
                                <div className="client-form-row">
                                  <div className="info-column">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="balloons_quantity"
                                        data-bs-toggle="tooltip" /*class="form-label"*/
                                      >
                                        {
                                          translations.viewClientForm
                                            .baloonsAccordian.vehiclesNum[
                                            language
                                          ]
                                        }
                                      </label>
                                      <Field
                                        disabled={!editable}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? editable
                                              ? "transparent"
                                              : "#ced4da66"
                                            : editable
                                            ? ""
                                            : "#ced4da66",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                        name="balloons_quantity"
                                        type="text" /*class="form-control"*/
                                      />

                                      <ErrorMessage
                                        name="balloons_quantity"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div className="info-column">
                                    <div className="mb-3">
                                      <div className="client-tooltip">
                                        <label
                                          htmlFor="balloons_colors"
                                          data-bs-toggle="tooltip" /*class="form-label"*/
                                        >
                                          {
                                            translations.viewClientForm
                                              .baloonsAccordian.desiredColors[
                                              language
                                            ]
                                          }
                                        </label>
                                        <span className="client-tooltiptext">
                                          {
                                            translations.viewClientForm
                                              .baloonsAccordian.tip[language]
                                          }
                                        </span>
                                      </div>
                                      <Field
                                        disabled={!editable}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? editable
                                              ? "transparent"
                                              : "#ced4da66"
                                            : editable
                                            ? ""
                                            : "#ced4da66",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                        name="balloons_colors"
                                        type="text" /*class="form-control"*/
                                      />
                                      <ErrorMessage
                                        name="balloons_colors"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {/* --------------------------------------------- */}

                      {false && (
                        <>
                          <div className="client-accordion-item">
                            <h2
                              className="client-accordion-header"
                              id="headingOne"
                              onClick={() => {
                                accordianShow !== 2
                                  ? setAccordianShow(2)
                                  : setAccordianShow(0);
                              }}
                            >
                              <button
                                className="client-accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                                fdprocessedid="em8y57"
                              >
                                <div>
                                  <strong>
                                    {translations.forms.conquestBtn[language]}{" "}
                                    &nbsp;
                                  </strong>
                                  <span className="client-badge">
                                    <img
                                      src={IconWarnig}
                                      style={{ width: 15 }}
                                      alt="Warning icon"
                                    ></img>{" "}
                                    {
                                      translations.viewClientForm.message2[
                                        language
                                      ]
                                    }
                                  </span>
                                </div>
                                <img
                                  className={
                                    accordianShow === 2 || showAll
                                      ? "arrow arrow-rotate"
                                      : "arrow"
                                  }
                                  src={IconExpand}
                                  alt=""
                                />
                              </button>
                            </h2>
                            {(accordianShow === 2 || showAll) && (
                              <div
                                id="collapseOne"
                                className="client-accordion-collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionIfApplicable"
                              >
                                <div className="client-accordion-body">
                                  <div className="client-form-row">
                                    <div className="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="postal_code"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.forms.formFields
                                                .conquestSection
                                                .targetedPostalCode[language]
                                            }
                                          </label>
                                          <span className="client-tooltiptext">
                                            {
                                              translations.forms.formFields
                                                .conquestSection.postalCode[
                                                language
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <Field
                                          disabled={!editable}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? editable
                                                ? "transparent"
                                                : "#ced4da66"
                                              : editable
                                              ? ""
                                              : "#ced4da66",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                          name="postal_code"
                                          type="text"
                                          /*class="form-control"*/ id="zone_cib"
                                        />
                                        <ErrorMessage
                                          name="postal_code"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="info-column">
                                      <div>
                                        <label
                                          htmlFor="regional_codes" /*class="form-label"*/
                                        >
                                          {
                                            translations.forms.formFields
                                              .conquestSection.regionalCodes[
                                              language
                                            ]
                                          }
                                        </label>
                                        <Field
                                          disabled={!editable}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? editable
                                                ? "transparent"
                                                : "#ced4da66"
                                              : editable
                                              ? ""
                                              : "#ced4da66",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                          name="regional_codes"
                                          type="text"
                                          /*class="form-control"*/ id="indic_reg"
                                        />
                                        <ErrorMessage
                                          name="regional_codes"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="info-column">
                                      <div>
                                        <label
                                          htmlFor="targeted_income" /*class="form-label"*/
                                        >
                                          {
                                            translations.forms.formFields
                                              .conquestSection.targetedIncome[
                                              language
                                            ]
                                          }
                                        </label>
                                        <Field
                                          as="select"
                                          name="targeted_income"
                                          class={` ${
                                            editable
                                              ? isDarkMode
                                                ? "custom-select form-select"
                                                : "form-select"
                                              : "form-control"
                                          }`}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? editable
                                                ? "transparent"
                                                : "#ced4da66"
                                              : "",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                          id="rev_cib"
                                          disabled={!editable}
                                        >
                                          <option
                                            vlaue=""
                                            selected=""
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            {
                                              translations.dropdown.select[
                                                language
                                              ]
                                            }
                                          </option>
                                          <option
                                            value={"25"}
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            25k and above
                                          </option>
                                          <option
                                            value={"50"}
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            50k and above
                                          </option>
                                          <option
                                            value={"75"}
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            75k and above
                                          </option>
                                          <option
                                            value={"100"}
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            100k and above
                                          </option>
                                        </Field>
                                        <ErrorMessage
                                          name="targeted_income"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div className="info-column">
                                      <div>
                                        <label
                                          htmlFor="target_age" /*class="form-label"*/
                                        >
                                          {
                                            translations.forms.formFields
                                              .conquestSection.targetAge[
                                              language
                                            ]
                                          }
                                        </label>
                                        <Field
                                          as="select"
                                          name="target_age"
                                          class={` ${
                                            editable
                                              ? isDarkMode
                                                ? "custom-select form-select"
                                                : "form-select"
                                              : "form-control"
                                          }`}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? editable
                                                ? "transparent"
                                                : "#ced4da66"
                                              : "",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                          id="age_cib"
                                          disabled={!editable}
                                        >
                                          <option
                                            value=""
                                            selected=""
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            {
                                              translations.dropdown.select[
                                                language
                                              ]
                                            }
                                          </option>
                                          <option
                                            value="18 - 65 years"
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            18 to 65 years
                                          </option>
                                          <option
                                            value="35 - 65 years"
                                            style={{
                                              background: isDarkMode
                                                ? "#1B1F22"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "",
                                            }}
                                          >
                                            35 to 65 years
                                          </option>
                                        </Field>
                                        <ErrorMessage
                                          name="target_age"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="client-accordion-item">
                            <h2
                              class="client-accordion-header"
                              id="headingTwo"
                              onClick={() => {
                                accordianShow !== 3
                                  ? setAccordianShow(3)
                                  : setAccordianShow(0);
                              }}
                            >
                              <button
                                class="client-accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <div>
                                  <strong>
                                    {
                                      translations.viewClientForm
                                        .massivePostalAccordian.title[language]
                                    }{" "}
                                    &nbsp;
                                  </strong>
                                  <span class="client-badge">
                                    <img
                                      src={IconWarnig}
                                      style={{ width: 15 }}
                                      alt="Warning icon"
                                    ></img>{" "}
                                    {
                                      translations.viewClientForm.message2[
                                        language
                                      ]
                                    }
                                  </span>
                                </div>
                                <img
                                  className={
                                    accordianShow === 3 || showAll
                                      ? "arrow arrow-rotate"
                                      : "arrow"
                                  }
                                  src={IconExpand}
                                  alt=""
                                />
                              </button>
                            </h2>
                            {(accordianShow === 3 || showAll) && (
                              <div
                                id="collapseTwo"
                                class="client-accordion-collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionIfApplicable"
                              >
                                <div class="client-accordion-body">
                                  <div class="client-form-row">
                                    <div class="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="postal_quantity"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.viewClientForm
                                                .massivePostalAccordian
                                                .quantity[language]
                                            }
                                          </label>
                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .massivePostalAccordian
                                                .noPostalMailings[language]
                                            }
                                          </span>
                                        </div>
                                        <Field
                                          name="postal_quantity"
                                          type="text" /*class="form-control"*/
                                          disabled={!editable}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? editable
                                                ? "transparent"
                                                : "#ced4da66"
                                              : editable
                                              ? ""
                                              : "#ced4da66",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="postal_quantity"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div class="info-column">
                                      <div>
                                        <div className="client-tooltip">
                                          <label
                                            htmlFor="targeted_territory"
                                            data-bs-toggle="tooltip" /*class="form-label"*/
                                          >
                                            {
                                              translations.forms.formFields
                                                .conquestSection
                                                .targetedTerritory[language]
                                            }
                                          </label>
                                          <span class="client-tooltiptext">
                                            {
                                              translations.viewClientForm
                                                .massivePostalAccordian.tip[
                                                language
                                              ]
                                            }
                                          </span>
                                        </div>
                                        <Field
                                          name="targeted_territory"
                                          type="text" /*class="form-control"*/
                                          disabled={!editable}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? editable
                                                ? "transparent"
                                                : "#ced4da66"
                                              : editable
                                              ? ""
                                              : "#ced4da66",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="targeted_territory"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {false && (
                        <div class="client-accordion-item">
                          <h2
                            class="client-accordion-header"
                            id="headingThree"
                            onClick={() => {
                              accordianShow !== 4
                                ? setAccordianShow(4)
                                : setAccordianShow(0);
                            }}
                          >
                            <button
                              class="client-accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <div>
                                <strong>
                                  {
                                    translations.viewClientForm.visualInfo
                                      .title[language]
                                  }{" "}
                                  &nbsp;
                                </strong>{" "}
                                <span class="client-badge ">
                                  <img
                                    src={IconWarnig}
                                    style={{ width: 15 }}
                                    alt="Warning icon"
                                  ></img>
                                  {
                                    translations.viewClientForm.message2[
                                      language
                                    ]
                                  }
                                </span>
                              </div>
                              <img
                                className={
                                  accordianShow === 4 || showAll
                                    ? "arrow arrow-rotate"
                                    : "arrow"
                                }
                                src={IconExpand}
                                alt=""
                              />
                            </button>
                          </h2>
                          {(accordianShow === 4 || showAll) && (
                            <div
                              id="collapseThree"
                              class="client-accordion-collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionIfApplicable"
                            >
                              <div class="client-accordion-body">
                                <div class="client-form-row">
                                  <div class="info-column">
                                    <div>
                                      <div className="client-tooltip">
                                        <label
                                          htmlFor="event_title"
                                          data-bs-toggle="tooltip" /*class="form-label"*/
                                        >
                                          {
                                            translations.viewClientForm
                                              .visualInfo.eventTitle[language]
                                          }{" "}
                                        </label>
                                        <span class="client-tooltiptext">
                                          {
                                            translations.viewClientForm
                                              .visualInfo.tip1[language]
                                          }
                                        </span>
                                      </div>
                                      <Field
                                        name="event_title"
                                        type="text"
                                        disabled={!editable}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? editable
                                              ? "transparent"
                                              : "#ced4da66"
                                            : editable
                                            ? ""
                                            : "#ced4da66",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="event_title"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div class="info-column">
                                    <div>
                                      <div className="client-tooltip">
                                        <label
                                          htmlFor="featured_vehicle"
                                          data-bs-toggle="tooltip"
                                        >
                                          {
                                            translations.viewClientForm
                                              .visualInfo.featuredVehicle[
                                              language
                                            ]
                                          }
                                        </label>
                                        <span class="client-tooltiptext">
                                          {
                                            translations.viewClientForm
                                              .visualInfo.tip2[language]
                                          }
                                        </span>
                                      </div>
                                      <Field
                                        name="featured_vehicle"
                                        type="text"
                                        disabled={!editable}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? editable
                                              ? "transparent"
                                              : "#ced4da66"
                                            : editable
                                            ? ""
                                            : "#ced4da66",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="featured_vehicle"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="client-form-row">
                                  <div class="info-column">
                                    <div /*class="mb-3"*/>
                                      <div className="client-tooltip">
                                        <label
                                          htmlFor="favorites"
                                          data-bs-toggle="tooltip" /*class="form-label"*/
                                        >
                                          {
                                            translations.viewClientForm
                                              .visualInfo.featuredVehicle2[
                                              language
                                            ]
                                          }
                                        </label>

                                        <span class="client-tooltiptext">
                                          {
                                            translations.viewClientForm
                                              .visualInfo.tip3[language]
                                          }
                                        </span>
                                      </div>
                                      <Field
                                        name="favorites"
                                        type="text" /*class="form-control"*/
                                        disabled={!editable}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? editable
                                              ? "transparent"
                                              : "#ced4da66"
                                            : editable
                                            ? ""
                                            : "#ced4da66",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="favorites"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div class="info-column">
                                    <div>
                                      <div className="client-tooltip">
                                        <label
                                          htmlFor="visual_language"
                                          data-bs-toggle="tooltip" /*class="form-label"*/
                                        >
                                          {
                                            translations.viewClientForm
                                              .visualInfo.languageOfVisual[
                                              language
                                            ]
                                          }
                                        </label>

                                        <span class="client-tooltiptext">
                                          {
                                            translations.viewClientForm
                                              .visualInfo.languageOfVisual[
                                              language
                                            ]
                                          }
                                        </span>
                                      </div>
                                      <Field
                                        as="select"
                                        name="visual_language"
                                        class={` ${
                                          editable
                                            ? isDarkMode
                                              ? "custom-select form-select"
                                              : "form-select"
                                            : "form-control"
                                        }`}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? editable
                                              ? "transparent"
                                              : "#ced4da66"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                        id="langue-des-visuelles"
                                        disabled={!editable}
                                      >
                                        <option
                                          vlaue=""
                                          selected=""
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations.dropdown.select[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value="French"
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations.commonWords.french[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value="English"
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations.commonWords.english[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value="Bilingual"
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations.commonWords.bilangual[
                                              language
                                            ]
                                          }
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="visual_language"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <div className="client-tooltip">
                                      <label
                                        htmlFor="promotions"
                                        data-bs-toggle="tooltip" /*class="form-label"*/
                                      >
                                        {
                                          translations.viewClientForm.visualInfo
                                            .promoHighlights[language]
                                        }
                                      </label>
                                      <span class="client-tooltiptext">
                                        {
                                          translations.viewClientForm.visualInfo
                                            .tip4[language]
                                        }
                                      </span>
                                    </div>
                                    <Field
                                      as="textarea"
                                      name="promotions"
                                      type="text"
                                      /*class="form-control"*/ id="event-promos"
                                      rows="2"
                                      disabled={!editable}
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? editable
                                            ? "transparent"
                                            : "#ced4da66"
                                          : editable
                                          ? ""
                                          : "#ced4da66",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="promotions"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    {/* --------------------------------------------- */}
                    <div
                      /*style={"padding:10px"}*/ class="alert-blue"
                      role="alert"
                    >
                      <p
                        style={{
                          fontSize: 20,
                          textAlign: "center",
                          marginBottom: 12,
                        }}
                      >
                        <strong>
                          {
                            translations.viewClientForm.usualSelections
                              .purification[language]
                          }
                        </strong>
                      </p>

                      <div className="clientMessage p-0">
                        <div class="mb-2" id="collapseExample">
                          <div className="client-card">
                            <h4>
                              {
                                translations.viewClientForm.impDocs.telephony[
                                  language
                                ]
                              }
                            </h4>
                            <p style={{ fontSize: "1rem", fontWeight: "bold" }}>
                              {
                                translations.viewClientForm.impDocs.note1.txt1[
                                  language
                                ]
                              }{" "}
                              <br />
                              {
                                translations.viewClientForm.impDocs.note1.txt2[
                                  language
                                ]
                              }
                            </p>
                            <div className="mt-1">
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#51abf5",
                                }}
                              >
                                {
                                  translations.viewClientForm.impDocs.singleList
                                    .txt1[language]
                                }
                              </p>
                              <p
                                style={{ fontSize: "1rem", fontWeight: "bold" }}
                              >
                                {
                                  translations.viewClientForm.impDocs.singleList
                                    .txt2[language]
                                }
                                <br />
                                <a
                                  href="https://www.youtube.com/watch?v=bqUE9yHoJew"
                                  target="_blank"
                                >
                                  https://www.youtube.com/watch?v=bqUE9yHoJew
                                </a>
                              </p>
                            </div>
                            <div className="mt-1">
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#51abf5",
                                }}
                              >
                                {
                                  translations.viewClientForm.impDocs
                                    .separateList.txt2[language]
                                }
                                <span> {values.due_date}:</span>
                              </p>
                              <ul className="mb-0">
                                <li
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    color: isDarkMode ? "white" : "black",
                                  }}
                                >
                                  {
                                    translations.viewClientForm.impDocs
                                      .separateList.pt1[language]
                                  }
                                </li>
                                <li
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                    color: isDarkMode ? "white" : "black",
                                  }}
                                >
                                  {
                                    translations.viewClientForm.impDocs
                                      .separateList.pt2[language]
                                  }
                                </li>
                              </ul>
                            </div>

                            <div>
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "#51abf5",
                                }}
                              >
                                {
                                  translations.viewClientForm.impDocs
                                    .essentialColumns.text1[language]
                                }
                                <br />{" "}
                                {
                                  translations.viewClientForm.impDocs
                                    .essentialColumns.thanks[language]
                                }
                              </p>
                            </div>

                            <div className="client-form-row">
                              <div className="info-column">
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {
                                    translations.viewClientForm.impDocs
                                      .essentialColumns.fields.alsoTxt[language]
                                  }
                                  <br />{" "}
                                  {
                                    translations.viewClientForm.impDocs
                                      .essentialColumns.thanks[language]
                                  }
                                </p>
                                <div className="client-form-row">
                                  <div className="info-column">
                                    <span
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {
                                        translations.viewClientForm
                                          .usualSelections.leaseCustomer.text1[
                                          language
                                        ]
                                      }
                                    </span>
                                    <ul style={{ fontSize: "1rem" }}>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .firstLastName[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.address2[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        Ville
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        Province
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.forms.formFields
                                            .conquestSection.postalCode[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .homeTelephone[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.cellPhone[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .officeTelephone[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .emailAddress[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .vehicleBrand[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .vehicleModel[language]
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="info-column">
                                    <span
                                      style={{
                                        fontSize: "1rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <br />
                                    </span>
                                    <ul style={{ fontSize: "1rem" }}>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .vehicleYear[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        NIV
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.advise[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .paymentMethod[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.type[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.term[
                                            language
                                          ]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: isDarkMode ? "white" : "black",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .purchaseDate[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields
                                            .lastPayment[language]
                                        }
                                      </li>
                                      <li
                                        style={{
                                          fontSize: "1rem",
                                          fontWeight: "bold",
                                          color: "red",
                                        }}
                                      >
                                        {
                                          translations.viewClientForm.impDocs
                                            .essentialColumns.fields.lastVisit[
                                            language
                                          ]
                                        }
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                Important:
                              </p>
                              <p
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "red",
                                }}
                              >
                                {
                                  translations.viewClientForm.impDocs
                                    .essentialColumns.fields.soldIn24[language]
                                }
                              </p>
                            </div>

                            <div className="pt-2">
                              <h4 /*style={"padding-bottom:10px"}*/>
                                {
                                  translations.viewClientForm.usualSelections
                                    .title[language]
                                }
                              </h4>
                              <p style={{ fontSize: "1rem" }}>
                                {
                                  translations.viewClientForm.usualSelections
                                    .note[language]
                                }
                              </p>
                              <ul>
                                <li
                                  style={{
                                    fontSize: "1rem",
                                    color: isDarkMode ? "white" : "black",
                                  }}
                                >
                                  <strong>
                                    {
                                      translations.viewClientForm
                                        .usualSelections.leaseCustomer.text1[
                                        language
                                      ]
                                    }
                                  </strong>{" "}
                                  {
                                    translations.viewClientForm.usualSelections
                                      .leaseCustomer.text2[language]
                                  }
                                </li>
                                <li
                                  style={{
                                    fontSize: "1rem",
                                    color: isDarkMode ? "white" : "black",
                                  }}
                                >
                                  <strong>
                                    {
                                      translations.viewClientForm
                                        .usualSelections.financingCustomer
                                        .text1[language]
                                    }
                                  </strong>{" "}
                                  {
                                    translations.viewClientForm.usualSelections
                                      .financingCustomer.text2[language]
                                  }
                                </li>
                                <li
                                  style={{
                                    fontSize: "1rem",
                                    color: isDarkMode ? "white" : "black",
                                  }}
                                >
                                  <strong>
                                    {
                                      translations.viewClientForm
                                        .usualSelections.serviceCustomer.text1[
                                        language
                                      ]
                                    }
                                  </strong>{" "}
                                  {
                                    translations.viewClientForm.usualSelections
                                      .serviceCustomer.text2[language]
                                  }
                                </li>
                              </ul>
                            </div>
                            <div class="client-form-row">
                              <div class="info-column">
                                <div /*class="mb-3"*/>
                                  <label
                                    for="modifications_instructions"
                                    data-bs-toggle="tooltip"
                                    title="" /*class="form-label"*/
                                  >
                                    {
                                      translations.viewClientForm
                                        .usualSelections.makeChanges[language]
                                    }
                                  </label>
                                  <Field
                                    as="textarea"
                                    name="modifications_instructions"
                                    type="text"
                                    disabled={!editable}
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? editable
                                          ? "transparent"
                                          : "#ced4da66"
                                        : editable
                                        ? ""
                                        : "#ced4da66",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                    /*class="form-control"*/ id="modifSelection"
                                  />
                                  <ErrorMessage
                                    name="modifications_instructions"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <div class="client-form-row d-flex  align-items-center ">
                                <div class="col-2-3rd">
                                  <div>
                                    <div className="client-tooltip">
                                      <label
                                        for="models_or_years_selection"
                                        data-bs-toggle="tooltip" /*class="form-label"*/
                                      >
                                        {
                                          translations.viewClientForm
                                            .usualSelections.specificModels[
                                            language
                                          ]
                                        }
                                      </label>
                                      <span class="client-tooltiptext">
                                        {
                                          translations.viewClientForm
                                            .usualSelections.tip1[language]
                                        }
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-1-3rd">
                                  <div>
                                    <Field
                                      as="select"
                                      name="models_or_years_selection"
                                      class={` ${
                                        editable
                                          ? isDarkMode
                                            ? "custom-select form-select"
                                            : "form-select"
                                          : "form-control"
                                      }`}
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? editable
                                            ? "transparent"
                                            : "#ced4da66"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                      aria-label="Default select example"
                                      id="modeleAnnPrio"
                                      disabled={!editable}
                                    >
                                      <option
                                        vlaue=""
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {translations.dropdown.select[language]}
                                      </option>
                                      <option
                                        value="Yes"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {translations.dropdown.yes[language]}{" "}
                                      </option>
                                      <option
                                        value="No"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {translations.dropdown.no[language]}{" "}
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="models_or_years_selection"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                {values.models_or_years_selection === "Yes" && (
                                  <div>
                                    <div>
                                      <label
                                        for="prioriti_reason"
                                        data-bs-toggle="tooltip"
                                        title="" /*class="form-label"*/
                                      >
                                        {
                                          translations.viewClientForm
                                            .usualSelections.iFYes[language]
                                        }
                                      </label>
                                      <Field
                                        as="textarea"
                                        name="prioriti_reason"
                                        type="text"
                                        disabled={!editable}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? editable
                                              ? "transparent"
                                              : "#ced4da66"
                                            : editable
                                            ? ""
                                            : "#ced4da66",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                        /*class="form-control"*/ id="modeleAnnPrioOui"
                                      />
                                      <ErrorMessage
                                        name="prioriti_reason"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="client-card">
                        <Dropzone
                          disabled={!editable}
                          style={{
                            backgroundColor: !editable ? "#ced4da66" : "",
                          }}
                          onDrop={(acceptedFiles) => {
                            // console.log(acceptedFiles)
                            if (acceptedFiles.length > 0) {
                              setFieldValue("files", [
                                ...values.files,
                                ...acceptedFiles,
                              ]);
                            }
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="client-form-row">
                              <div>
                                <p style={{ fontSize: "1rem" }}>
                                  {
                                    translations.viewClientForm.submitSection
                                      .text1[language]
                                  }
                                </p>
                                <label
                                  htmlFor="files"
                                  className="mt-4"
                                  style={{
                                    color: isDarkMode ? "#33dbb4" : "",
                                    fontSize: "20px",
                                    fontWeight: 600,
                                  }} /*class="form-label"*/
                                >
                                  {
                                    translations.viewClientForm.submitSection
                                      .selectedFiles[language]
                                  }
                                </label>

                                <div
                                  {...getRootProps()}
                                  role="presentation"
                                  tabIndex="0"
                                  style={{
                                    background: "rgb(231, 236, 239)",
                                    padding: "20px 0px",
                                    borderRadius: "0.375rem",
                                    height: "350px",
                                    display: "flex",
                                    alignContent: "center",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <input {...getInputProps()} type="file" />

                                  <div className="client-file-input">
                                    <p
                                      style={{
                                        textAlign: "center",
                                        fontSize: "15px",
                                        fontWeight: 700,
                                        marginBottom: "60px",
                                      }}
                                    >
                                      {
                                        translations.viewClientForm
                                          .submitSection.DnD[language]
                                      }
                                    </p>
                                    <i
                                      className="bi bi-file-earmark-arrow-up-fill"
                                      style={{
                                        border: "10px dashed #babec1",
                                        padding: "45px 175px",
                                        color: "#babec1",
                                        borderRadius: "0.375rem",
                                        fontSize: "50pt",
                                        marginTop: "20px",
                                      }}
                                    ></i>
                                    {/* <input
                                                              type="file"
                                                              id="files"
                                                              multiple
                                                              value={values.files}
                                                              disabled
                                                              // onChange={(e) => {
                                                              //     setValues({ ...values, files: [...e.target.files] })
                                                              // }}
                                                          /> */}
                                    {/* {console.log(values.files)} */}
                                  </div>
                                </div>
                                <ErrorMessage
                                  name="files"
                                  component="div"
                                  className="error text-danger"
                                />

                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                  className="my-2"
                                >
                                  {
                                    translations.viewClientForm.submitSection
                                      .oldfiles[language]
                                  }
                                </p>
                                {oldFiles &&
                                  oldFiles.length > 0 &&
                                  oldFiles.map((file, i) => (
                                    <div className="d-flex gap-3 align-items-center ">
                                      <div className="d-flex gap-1 pt-1 align-items-center ">
                                        <i class="bi bi-file-earmark-check"></i>
                                        <p>{file.type}</p>
                                      </div>
                                    </div>
                                  ))}

                                {values.files && values.files.length > 0 && (
                                  <p
                                    className="py-2 "
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: 700,
                                    }}
                                  >
                                    Selected Files:
                                  </p>
                                )}

                                {values.files &&
                                  values.files.length > 0 &&
                                  values.files.map((file, i) => (
                                    <div className="d-flex gap-3">
                                      <div className="d-flex gap-1 align-items-center ">
                                        <i class="bi bi-file-earmark-check"></i>
                                        <p>{file.name}</p>
                                        <i
                                          class="bi bi-trash"
                                          onClick={() =>
                                            setValues({
                                              ...values,
                                              files: values.files.filter(
                                                (item, ind) => ind !== i
                                              ),
                                            })
                                          }
                                        ></i>
                                      </div>
                                    </div>
                                  ))}

                                {/* <Field type="hidden" id="fileData" name="fileData" />
                                          <Field type="hidden" id="mimeType" name="mimeType" />
                                          <Field type="hidden" id="fileName" name="fileName" /> */}
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                    {editable && (
                      <>
                        <div
                          style={{ marginBottom: 16 }}
                          onClick={() => console.log(values)}
                        >
                          {/* <ReCAPTCHA
                            name="recaptcha"
                            sitekey="6Ld7dhkjAAAAANUYYEVWh9pFiaqrxtYLC2bzF2Sa"
                            onChange={(val) => {
                              setValues({ ...values, recapchaVal: val });
                            }}
                          ></ReCAPTCHA>
                          <ErrorMessage
                            name="recapchaVal"
                            component="div"
                            className="error text-danger"
                          /> */}
                          {/* <div id="recaptcha" class="g-recaptcha" data-sitekey="6Lcor4EhAAAAAOhhlB--KaLKIz1Aa15l4egtdiMW">
                      <div style="width: 304px; height: 78px;">
                          <div>
                              <iframe title="reCAPTCHA" width="304" height="78" role="presentation" name="a-y0zbd8dy84el" frameborder="0" scrolling="no"
                                  sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                                  src="https://www.google.com/recaptcha/api2/anchor?ar=2&amp;k=6Lcor4EhAAAAAOhhlB--KaLKIz1Aa15l4egtdiMW&amp;co=aHR0cHM6Ly9uLXJyNW83NjN3bmV5eG9haGZtMmFjNHQzZHlyeTd3bWpjNWpwcWZwcS0wbHUtc2NyaXB0Lmdvb2dsZXVzZXJjb250ZW50LmNvbTo0NDM.&amp;hl=en&amp;v=cwQvQhsy4_nYdnSDY4u7O5_B&amp;size=normal&amp;cb=l9uhpdelio4h">
                              </iframe>
                          </div>
                          <textarea id="g-recaptcha-response" name="g-recaptcha-response" class="g-recaptcha-response"
                              style="width: 250px; height: 40px; border: 1px solid rgb(193, 193, 193); margin: 10px 25px; padding: 0px; resize: none; display: none;">
                          </textarea>
                      </div>
                      <iframe style="display: none;">
                      </iframe>
                  </div> */}
                        </div>

                        <button
                          className="btn  btn-light"
                          id="save-changes-v2"
                          type="submit"
                          value="Soumettre"
                          disabled={!editable}
                          onClick={() => {
                            let stopLoop = false;
                            Object.keys(errors).length > 0 &&
                              Object.keys(errors).some(
                                (field, index, array) => {
                                  if (stopLoop) return;
                                  if (Array.isArray(errors[field])) {
                                    errors[field].forEach((element) => {
                                      toast.error(element);
                                    });
                                  } else {
                                    setShowAll(true);
                                    toast.error(errors[field]);
                                  }
                                  if (index === array.length - 1) {
                                    stopLoop = true;
                                  }
                                  return stopLoop;
                                }
                              );
                          }}
                        >
                          <i class="bi bi-box-arrow-down"></i>{" "}
                          {translations.commonFields.submitBtn[language]}
                        </button>
                      </>
                    )}
                  </Form>
                )}
              </Formik>
            ) : (
              <div className="text-center py-4">
                <h5 className="text-danger">
                  You have hit the deadline, you cannot submit anymore!{" "}
                </h5>{" "}
                <h6 className="text-danger">
                  {" "}
                  The deadline for form submission is:
                  <strong> {formValues.first_day}</strong>
                </h6>
              </div>
            )}
          </div>
        )}
        {/* <p class="footertext">Propulsé par Fidgi Communications.<br /> © 2022 FIDGI Communications. Tous droits réservés.</p> */}
      </div>
    </div>
  );
};

export default ViewClientSubmission;
