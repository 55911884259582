import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import SendFormModal from "./SendFormModal";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import SendPdfModal from "./sendPdfModal";
import Loader from "./loader";
import AddWeekModal from "./addWeekModal";
import Select from "react-select";

const EditEventForm = ({ setDisplayComponent, isDarkMode , users }) => {
  const [isOpenAll, setIsOpenAll] = useState(false);
  const [formType, setFormType] = useState(null);
  const [submitPressed, setSubmitPressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  const [showDate, setShowDate] = useState(true);
  const [formValues, setFormValues] = useState();
  const [userEmails, setUserEmails] = useState();

  // Client Forms States
  const [clientForms, setClientForms] = useState();
  const [documents, setDocuments] = useState();
  const [editEventID, setEditEventID] = useState();
  const [formUrl, setFormUrl] = useState(null);
  const [week, setWeek] = useState(null);
  const [driveLink, setDriveLink] = useState(null);
  const [packages, setPackages] = useState([]);
  const { language } = useLanguage();
  const pageTranslations = translations["forms"];
  const repOptions =
  users &&
  users.representatives.length > 0 &&
  users.representatives.map((user) => ({
    value: user.id,
    label: user.name,
  }));
  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const ampersandIndex = currentUrl.indexOf("&", secondEqualsIndex + 1); //=41&p=27

        if (ampersandIndex !== -1) {
          const editClientIdValue = currentUrl.substring(
            secondEqualsIndex + 1,
            ampersandIndex
          ); //=41
          setEditEventID(editClientIdValue);

          const thirdEqualsIndex = currentUrl.indexOf("=", ampersandIndex + 1); // =27

          if (thirdEqualsIndex !== -1) {
            const eventIDValue = currentUrl.substring(thirdEqualsIndex + 1);
            setEditEventID(eventIDValue);

          }
        } else {
          // If no '&' found after the second '=', extract until the end of the URL
          const editClientIdValue = currentUrl.substring(secondEqualsIndex + 1);
          setEditEventID(editClientIdValue);
        }
      }
    }
  });

  const handleToggleAll = () => {
    setIsOpenAll((prevIsOpenAll) => !prevIsOpenAll);
    setShowDate(false);
  };

  const validationSchema = Yup.object().shape({
    days: Yup.number().required(
      pageTranslations.validationTranslations.days[language]
    ),
    first_day: Yup.date().required(
      pageTranslations.validationTranslations.first_day[language]
    ),
    contact_tp_email: Yup.string().email(
      translations.usersPage.createUser.validationText.email.invalid[language]
    ),
    contact_list_email: Yup.string().email(
      translations.usersPage.createUser.validationText.email.invalid[language]
    ),
  });

  const handleSubmit = async (values, { resetForm }, error) => {
    // console.log("values: ", values);
    setLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/create-parentform",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(values),
        }
      );
      setFormValues(values);
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const saveChanges = async (formType, formData) => {
    try {
      const res = await fetch(
        ApiUrl + "/api/user/create-parentform",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      setFormValues(formData);
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success("Changes Saved Successfully");
        if (formType) handleFormDownload(formType);
      } else {
        toast.error(data.message);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchFormData = async () => {
      try {
        const res = await fetch(
          ApiUrl + "/api/user/get-parentform",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              id: editEventID,
            }),
          }
        );
        // console.log("res Get Form data", res)
        const data = await res.json();
        // console.log("data get ", data)
        if (data.status === "success") {
          getClientForms();
          fetchDocuments();
          // toast.success(data.message)
          const data2 = data.formData;
          if (data.formData !== undefined && data.formData != null) {
            for (const key in data2) {
              if (data2[key] === null || data2[key] === "null") {
                data2[key] = "";
              }
            }
          }
          setFormValues(data2);
          setUserEmails(data.user_email_list);
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error);
      }
    };
    const getPackagess = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/package-index/allPackage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const packageOptions =
    data.packages &&
    data.packages.length > 0 &&
    data.packages.map((item) => ({
      value: item.id,
      label: item.name,
    }));
          setPackages(packageOptions);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching dealerships:", error);
        // Handle error or show a toast if needed
      }
    };

    fetchFormData();
    getPackagess();
  }, [editEventID]);

  // fetch documents
  const fetchDocuments = async () => {
    try {
      const res = await fetch(
        ApiUrl + "/api/user/get_documents",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            parent_id: editEventID,
          }),
        }
      );
      // console.log("res Get Form data", res)
      const data = await res.json();
      // console.log("data get ", data)
      if (data.status === "success") {
        setDocuments(data.event_list);
        // console.log(data.event_list)
        // console.log(data);
        // toast.success(data.message)
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const getClientForms = async () => {
    try {
      const res = await fetch(
        ApiUrl + "/api/user/get-childform-submittions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            parent_id: editEventID,
          }),
        }
      );
      const data = await res.json();

      if (data.status === "success") {
        setClientForms(data.client_submittions_list);
      } else {
        toast.success(data.message);
        // console.log(data.message)
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleFormDownload = async (api) => {
    setFormUrl(null);
    try {
      const res = await fetch(ApiUrl + "/api/user/"+ api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          parent_id: editEventID,
        }),
      });
      const data = await res.json();

      if (data.status === "success") {
        // console.log(data)
        setFormUrl(data.pdf_url);
        setDriveLink(data.drive_response);

        fetchDocuments();
      } else {
        toast.success(data.message);
        // console.log(data.message)
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  const sendDocument = async (file_type, drive_link, week, dealer_name) => {
    setLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/send-mail-to-admins-submisstion-docs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            dealer_name: dealer_name,
            file_type: file_type,
            drive_link: drive_link,
            week: week,
          }),
        }
      );
      const data = await res.json();

      if (data.status === "success") {
        // console.log(data)
        setLoading(false);
        toast.success(`${data.message}`);
      } else {
        toast.success(data.message);
        // console.log(data.message)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };
  // const handleFormDownload = async (api) => {
  //   try {
  //     const res = await fetch(ApiUrl + "/api/user/" + userType + "-" + api, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //       body: JSON.stringify({
  //         parent_id: editEventID,
  //       }),
  //     });
  //     const data = await res.json();

  //     if (data.status === "success") {
  //       // console.log(data)
  //       window.open(data.pdf_url, "_blank");
  //     } else {
  //       toast.success(data.message);
  //       // console.log(data.message)
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong!");
  //   }
  // };

  return (
    <div className="create-event">
      {loading ? (
        <Loader />
      ) : (
        <div
          class="container maincont"
          // style={{ height: "calc(100vh - 108px)" }}
        >
          <div id="app" className="appcountainer2">
            {clientForms && formValues && (
              <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, values, setValues }) => (
                  <Form id="edit-event-form">
                    {/* Display a toast for each field with an error */}
                    {(() => {
                      let stopLoop = false;
                      submitPressed &&
                        Object.keys(errors).length > 0 &&
                        Object.keys(errors).some((field, index, array) => {
                          if (stopLoop) return;
                          toast.error(errors[field]);

                          if (index === array.length - 1) {
                            setSubmitPressed(false);
                            stopLoop = true;
                          }

                          return stopLoop;
                        });
                    })()}
                    <div class="edit-form-form">
                      <div class="headersec">
                        <nav class="navbar navbar-expand-md navbar-light">
                          <div class="container-fluid">
                            <h2 class="navbar-brand">
                              {pageTranslations.edit[language]}
                            </h2>

                            <button
                              class="navbar-toggler"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#navbarNav"
                              aria-controls="navbarNav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                            >
                              <span class="navbar-toggler-icon"></span>
                            </button>
                            <div
                              class="collapse navbar-collapse justify-content-end"
                              id="navbarNav"
                            >
                              <ul class="navbar-nav">
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    href="#multiCollapseDate"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseDate" /* onClick={() => setDarkTrigger((prev) => !prev)}*/
                                  >
                                    <i class="bi bi-calendar"></i>{" "}
                                    {pageTranslations.datesBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseOptions"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseOptions" /*onClick={() => setDarkTrigger((prev) => !prev)}*/
                                  >
                                    <i class="bi bi-people-fill"></i>{" "}
                                    {pageTranslations.conquestBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfosvente"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfosvente" /*onClick={() => setDarkTrigger((prev) => !prev)}*/
                                  >
                                    <i class="bi bi-info-circle"></i>
                                    {pageTranslations.salesInfoBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfoscontact"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfoscontact" /*onClick={() => setDarkTrigger((prev) => !prev)}*/
                                  >
                                    <i class="bi bi-person-lines-fill"></i>{" "}
                                    {pageTranslations.contactBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfosequipe"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfosequipe" /*onClick={() => setDarkTrigger((prev) => !prev)}*/
                                  >
                                    <i class="bi bi-person-badge"></i>{" "}
                                    {pageTranslations.teamBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfospromos"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfospromos" /*onClick={() => setDarkTrigger((prev) => !prev)}*/
                                  >
                                    <i class="bi bi-stars"></i>{" "}
                                    {pageTranslations.promosBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    className="admin-btn btn-primary"
                                    type="button"
                                    onClick={handleToggleAll}
                                  >
                                    <i className="bi bi-folder2-open"></i>{" "}
                                    {isOpenAll
                                      ? pageTranslations.closeAllBtn[language]
                                      : pageTranslations.openAllBtn[language]}
                                  </button>
                                  {/* <button class="admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseDate multiCollapseOptions multiCollapseInfosvente multiCollapseInfoscontact multiCollapseInfosequipe multiCollapseInfospromos"><i class="bi bi-folder2-open"></i> Open All</button> */}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </nav>
                        {clientForms && clientForms.length > 0 && (
                          <div
                            className="my-1 p-2 rounded-2"
                            style={{
                              backgroundColor: isDarkMode
                                ? "#48CAE4"
                                : "#F8D7DA",
                            }}
                          >
                            {translations.forms.message[language]}
                          </div>
                        )}
                      </div>

                      <div class="bodysectab">
                        <div class="row">
                          {/* <div class="col">
                            <div class="mb-3">
                              <label for="event-1-ID" class="form-label">
                                ID
                              </label>
                              <Field
                                type="number"
                                class="form-control"
                                id="event-1-ID"
                                name="id"
                                disabled
                                fdprocessedid="2r24a"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "rgba(79,87,89,0.5)"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              />
                              <ErrorMessage
                                name="id"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div> */}
                          <div class="col">
                            <div class="mb-3">
                              <label for="dealership-name2" class="form-label">
                                {
                                  translations.forms.event2[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                type="text"
                                class="form-control"
                                name="nom_vp"
                                disabled
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "#ced4da66"
                                    : "#ced4da66",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                id="dealership-name2" /*data-show-subtext="true" data-live-search="true"  aria-label="Dealer" fdprocessedid="qo2vc8"*/
                              >
                                {/* <option selected=""></option>
                            {dealerships && dealerships.map((dealership) => (
                              < option key={dealership.id} value={dealership.id}>{dealership.name}</option>
                            ))} */}
                              </Field>
                              <ErrorMessage
                                name="dealer"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div class="col">
                        <div class="mb-3">
                          <label for="event-1-Week" class="form-label">
                            {pageTranslations.formFields.week[language]}
                          </label>
                          <Field
                            type="number"
                            style={{
                              backgroundColor: isDarkMode ? "#ced4da66" : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            class="form-control"
                            name="week"
                            id="event-1-Week"
                            fdprocessedid="2r24a"
                            disabled={true}
                          />
                          <ErrorMessage
                            name="week"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                          <div class="col">
                            <div class="mb-3">
                              <label for="package" class="form-label">
                                {pageTranslations.formFields.package[language]}
                              </label>
                              {/* <Field
                                type="text"
                                name="package"
                                class="form-control"
                                id="package"
                                fdprocessedid="v05rnp"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              /> */}
                              <Field
                                name="package"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={packages}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={
                                      (packages &&
                                        packages.length > 0 &&
                                        packages.find(
                                          (option) =>
                                          
                                            option.value?.toString() === field.value?.toString()
                                        )) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) =>
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      )
                                    }
                                    isDisabled
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "#ced4da66"
                                          : "#ced4da66",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                        display:"none"
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="package"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="mb-3">
                              <label class="form-label">
                                {
                                  pageTranslations.formFields
                                    .sales_representative[language]
                                }
                              </label>
                              <Field
                            name="sales_representative"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={repOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isDisabled
                                isSearchable
                                value={
                                  (repOptions &&
                                    repOptions.length > 0 &&
                                    repOptions.find(
                                      (option) => option.value?.toString() === field.value?.toString()
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                    ? "#ced4da66"
                                    : "#ced4da66",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                    display: "none"
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                              <ErrorMessage
                                name="sales_representative"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div class="mb-3">
                              <label for="last-sale-date" class="form-label">
                                {
                                  pageTranslations.formFields.lastSaleDate[
                                    language
                                  ]
                                }
                              </label>
                              <Field
                                type="date"
                                name="last_sale_date"
                                class={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                style={{
                                  backgroundColor: isDarkMode ? "#ced4da66" : "#ced4da66",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                id="last-sale-date"
                                disabled
                              />
                              <ErrorMessage
                                name="last_sale_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div class="col">
                            <div class="mb-3">
                              <label
                                for="annual-sales-count"
                                class="form-label"
                              >
                                {pageTranslations.formFields.digital[language]}
                              </label>
                              {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                              <Field
                                as="select"
                                class={`form-select ${
                                  isDarkMode ? "custom-select" : ""
                                }`}
                                name="digital"
                                aria-label="Digital"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              >
                                <option
                                  value=""
                                  selected=""
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  {translations["dropdown"].select[language]}
                                </option>
                                <option
                                  value={"Yes"}
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  {" "}
                                  {translations["dropdown"].yes[language]}
                                </option>
                                <option
                                  value={"No"}
                                  style={{
                                    background: isDarkMode ? "#1B1F22" : "",
                                    color: isDarkMode ? "#ffffff" : "",
                                  }}
                                >
                                  {translations["dropdown"].no[language]}
                                </option>
                              </Field>
                              <ErrorMessage
                                name="digital"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          class="row align-middle"
                          style={{ paddingBottom: 10 }}
                        >
                          <div
                            class={`collapse multi-collapse ${
                              isOpenAll || showDate ? "show" : ""
                            }`}
                            id="multiCollapseDate"
                          >
                            <div
                              class="card card-body"
                              style={{
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            >
                              <h2
                                className={`${isDarkMode ? "darkthead" : ""}`}
                              >
                                {
                                  pageTranslations.formFields.datesSection
                                    .dates[language]
                                }
                              </h2>
                              {values.first_day &&
                              values.event_dates &&
                              values.week ? (
                                <p></p>
                              ) : (
                                <>
                                <p className="text-danger pb-2">
                                  {
                                    pageTranslations.formFields.datesSection
                                      .message[language]
                                  }
                                </p>
                                
                              </>
                              )}
                              <div class="row">
                                <div class="col-2 align-middle">
                                  <div class="mb-3">
                                    <p>
                                      {
                                        pageTranslations.formFields.datesSection
                                          .title[language]
                                      }
                                    </p>
                                  </div>
                                </div>
                                <div class="col-2">
                                  <div class="mb-3">
                                    <p>
                                      1
                                      <sup>
                                        {
                                          pageTranslations.formFields
                                            .datesSection.st[language]
                                        }
                                      </sup>{" "}
                                      {
                                        pageTranslations.formFields.datesSection
                                          .day[language]
                                      }
                                    </p>
                                  </div>
                                </div>

                                <div class="col-1">
                                  <div class="mb-3">
                                    <p>
                                      {
                                        pageTranslations.formFields.datesSection
                                          .days[language]
                                      }
                                    </p>
                                  </div>
                                </div>

                                <div class="col-7">
                                  <div class="mb-3">
                                    <p>
                                      {
                                        pageTranslations.formFields.datesSection
                                          .EventsDates[language]
                                      }
                                    </p>
                                  </div>
                                </div>
                                {/* <div class="col-2">
                                  <div class="mb-3">
                                    <p>
                                      {
                                        pageTranslations.formFields.week[
                                          language
                                        ]
                                      }
                                    </p>
                                  </div>
                                </div> */}
                              </div>
                              <div class="row align-middle">
                                <div class="col-2 align-middle">
                                  <div class="mb-3">
                                    <p>
                                      1
                                      <sup>
                                        {
                                          pageTranslations.formFields
                                            .datesSection.st[language]
                                        }
                                      </sup>{" "}
                                      {
                                        translations["EventsPortal"].title
                                          .events[language]
                                      }
                                    </p>
                                  </div>
                                </div>

                                <div class="col-2">
                                  <div class="mb-3">
                                    <Field
                                      type="date"
                                      name="first_day"
                                      onChange={(e) => {
                                        if (values.days && e.target.value) {
                                          let daysValues = "";
                                          for (
                                            let i = 0;
                                            i < Number(values.days);
                                            i++
                                          ) {
                                            const newDate = new Date(
                                              e.target.value
                                            );
                                            newDate.setDate(
                                              newDate.getDate() + i
                                            );
                                            const formattedDate = newDate
                                              .toISOString()
                                              .split("T")[0];
                                            daysValues = daysValues
                                              ? daysValues +
                                                " / " +
                                                formattedDate
                                              : formattedDate;
                                          }
                                          setValues({
                                            ...values,
                                            first_day: e.target.value,
                                            event_dates: daysValues,
                                          });
                                        } else {
                                          setValues({
                                            ...values,
                                            first_day: e.target.value,
                                          });
                                        }
                                      }}
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "#ced4da66"
                                          : "#ced4da66",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                      class={`form-control ${
                                        isDarkMode ? "custom-date" : ""
                                      }`}
                                      id="date_day_1" /*onchange="datesCalculator()" */
                                    />
                                    <ErrorMessage
                                      name="first_day"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col-1">
                                  <Field
                                    as="select"
                                    class={`form-select ${
                                      isDarkMode ? "custom-select" : ""
                                    }`}
                                    style={{
                                      backgroundColor: isDarkMode
                                        ? "transparent"
                                        : "",
                                      color: isDarkMode ? "#ffffff" : "#000000",
                                      border: isDarkMode
                                        ? "1px solid #495057"
                                        : "1px solid #dee2e6",
                                    }}
                                    name="days"
                                    aria-label="Default select example"
                                    id="nb_day_1"
                                    onChange={(e) => {
                                      if (e.target.value && values.first_day) {
                                        let daysValues = "";
                                        for (
                                          let i = 0;
                                          i < Number(e.target.value);
                                          i++
                                        ) {
                                          const newDate = new Date(
                                            values.first_day
                                          );
                                          newDate.setDate(
                                            newDate.getDate() + i
                                          );
                                          const formattedDate = newDate
                                            .toISOString()
                                            .split("T")[0];
                                          daysValues = daysValues
                                            ? daysValues + " / " + formattedDate
                                            : formattedDate;
                                        }
                                        setValues({
                                          ...values,
                                          days: e.target.value,
                                          event_dates: daysValues,
                                        });
                                      } else {
                                        setValues({
                                          ...values,
                                          days: e.target.value,
                                        });
                                      }
                                    }}
                                    /*onchange="datesCalculator()"*/ fdprocessedid="0w4dw"
                                  >
                                    <option
                                      value=""
                                      selected=""
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      {
                                        pageTranslations.formFields.datesSection
                                          .numDays[language]
                                      }
                                    </option>
                                    <option
                                      value="1"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      1
                                    </option>
                                    <option
                                      value="2"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      2
                                    </option>
                                    <option
                                      value="3"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      3
                                    </option>
                                    <option
                                      value="4"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      4
                                    </option>
                                    <option
                                      value="5"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      5
                                    </option>
                                    <option
                                      value="6"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      6
                                    </option>
                                    <option
                                      value="7"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      7
                                    </option>
                                    <option
                                      value="8"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      8
                                    </option>
                                    <option
                                      value="9"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      9
                                    </option>
                                    <option
                                      value="10"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      10
                                    </option>
                                    <option
                                      value="11"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      11
                                    </option>
                                    <option
                                      value="12"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      12
                                    </option>
                                    <option
                                      value="13"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      13
                                    </option>
                                    <option
                                      value="14"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      14
                                    </option>
                                    <option
                                      value="15"
                                      style={{
                                        background: isDarkMode ? "#1B1F22" : "",
                                        color: isDarkMode ? "#ffffff" : "",
                                      }}
                                    >
                                      15
                                    </option>
                                  </Field>
                                  <ErrorMessage
                                    name="days"
                                    component="div"
                                    className="error text-danger"
                                  />
                                </div>
                                <div class="col-7">
                                  <div class="mb-3">
                                    <Field
                                      as="textarea"
                                      class="form-control"
                                      name="event_dates"
                                      disabled
                                      readOnly
                                      id="date_event_1"
                                      rows="2"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "#ced4da66"
                                          : "#ced4da66",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    ></Field>
                                    <ErrorMessage
                                      name="event_dates"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                                {/* <div class="col-2">
                                  <div class="mb-3">
                                    <Field
                                      type="text"
                                      name="week"
                                      readOnly
                                      disabled
                                      class="form-control"
                                      id="semaine-input"
                                      fdprocessedid="g00vec"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "rgba(79,87,89,0.5)"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="week"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>

                        {false && (
                          <div class="row" style={{ paddingBottom: 10 }}>
                            <div
                              class={`collapse multi-collapse ${
                                isOpenAll ? "show" : ""
                              }`}
                              id="multiCollapseOptions"
                            >
                              <div
                                class="card card-body"
                                style={{
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              >
                                <h2
                                  className={`${isDarkMode ? "darkthead" : ""}`}
                                >
                                  {
                                    pageTranslations.formFields.conquestSection
                                      .title[language]
                                  }
                                </h2>

                                <div class="row">
                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="opt_conq"
                                        data-bs-toggle="tooltip"
                                        title="Reconquest (inactive) or conquest (infocanda)"
                                        class="form-label"
                                      >
                                        Type
                                      </label>

                                      <Field
                                        as="select"
                                        class={`form-select ${
                                          isDarkMode ? "custom-select" : ""
                                        }`}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                        aria-label="Default select example"
                                        name="type_conquest"
                                        id="opt_conq"
                                      >
                                        <option
                                          value="Conquest"
                                          selected=""
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            pageTranslations.formFields
                                              .conquestSection.title[language]
                                          }
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="type_conquest"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="opt-conquetes-nb-pros"
                                        data-bs-toggle="tooltip"
                                        title="Number of prospects for pure conquest"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.requiredQty[
                                            language
                                          ]
                                        }
                                      </label>
                                      <Field
                                        /*onchange="calctotalconquest()"*/ name="quantity"
                                        type="text"
                                        class="form-control"
                                        id="opt-conquetes-nb-pros"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="quantity"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="opt-conquetes-nb-comm"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.toOrder[language]
                                        }
                                      </label>
                                      <Field
                                        type="text"
                                        name="to_order"
                                        class="form-control"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                        id="opt-conquetes-nb-comm"
                                      />
                                      <ErrorMessage
                                        name="to_order"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="zone_cib"
                                        data-bs-toggle="tooltip"
                                        title="Postal Code"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.postalCode[
                                            language
                                          ]
                                        }
                                      </label>
                                      <Field
                                        type="text"
                                        name="postal_code"
                                        class="form-control"
                                        id="zone_cib"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="postal_code"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="mb-3">
                                      <label for="indic_reg" class="form-label">
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.regionalCodes[
                                            language
                                          ]
                                        }{" "}
                                      </label>
                                      <Field
                                        type="text"
                                        name="regional_codes"
                                        class="form-control"
                                        id="indic_reg"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="regional_codes"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="mb-3">
                                      <label for="rev_cib" class="form-label">
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.targetedIncome[
                                            language
                                          ]
                                        }{" "}
                                      </label>
                                      <Field
                                        as="select"
                                        class={`form-select ${
                                          isDarkMode ? "custom-select" : ""
                                        }`}
                                        name="targeted_income"
                                        id="rev_cib"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      >
                                        <option
                                          value=""
                                          selected=""
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations["dropdown"].select[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value={25}
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          25k{" "}
                                          {
                                            translations["dropdown"].andMore[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value={50}
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          50k{" "}
                                          {
                                            translations["dropdown"].andMore[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value={75}
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          75k{" "}
                                          {
                                            translations["dropdown"].andMore[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value={100}
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          100k{" "}
                                          {
                                            translations["dropdown"].andMore[
                                              language
                                            ]
                                          }
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="targeted_income"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div class="col">
                                    <div class="mb-3">
                                      <label for="age_cib" class="form-label">
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.targetAge[language]
                                        }{" "}
                                      </label>
                                      <Field
                                        as="select"
                                        class={`form-select ${
                                          isDarkMode ? "custom-select" : ""
                                        }`}
                                        name="target_age"
                                        id="age_cib"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      >
                                        <option
                                          value=""
                                          selected=""
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations["dropdown"].select[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value="18 - 65 years"
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations["dropdown"].year18to65[
                                              language
                                            ]
                                          }
                                        </option>
                                        <option
                                          value="35 - 65 years"
                                          style={{
                                            background: isDarkMode
                                              ? "#1B1F22"
                                              : "",
                                            color: isDarkMode ? "#ffffff" : "",
                                          }}
                                        >
                                          {
                                            translations["dropdown"].year35to65[
                                              language
                                            ]
                                          }
                                        </option>
                                      </Field>
                                      <ErrorMessage
                                        name="target_age"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="lnnte_acc_abb"
                                        data-bs-toggle="tooltip"
                                        title="Number of Postal Sends"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.subcriptionAN[
                                            language
                                          ]
                                        }
                                      </label>
                                      <Field
                                        type="number"
                                        name="access_number"
                                        class="form-control"
                                        id="lnnte_acc_abb"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="access_number"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>

                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="lnnte_acc_key"
                                        data-bs-toggle="tooltip"
                                        title="Number of Postal Sends"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .conquestSection.downloadKey[
                                            language
                                          ]
                                        }
                                      </label>
                                      <Field
                                        type="text"
                                        name="download_key"
                                        class="form-control"
                                        id="lnnte_acc_key"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="download_key"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  {/* <div
                                  class="col-sm"
                                  style={{ marginBottom: "10px !important" }}
                                >
                                  <button
                                    type="submit"
                                    class="client-btn btn-primary"
                                    id="form-conq"
                                  >
                                    {
                                      pageTranslations.formFields
                                        .conquestSection.generateFormBtn[
                                        language
                                      ]
                                    }
                                  </button>
                                </div> */}
                                  <hr />
                                  <div class="row">
                                    <div class="col">
                                      <div class="mb-3">
                                        <label
                                          for="opt-conquetes-nb-postal"
                                          data-bs-toggle="tooltip"
                                          title="Number of Postal Sends"
                                          class="form-label"
                                        >
                                          {
                                            pageTranslations.formFields
                                              .conquestSection.noPostalSends[
                                              language
                                            ]
                                          }
                                        </label>
                                        <Field
                                          type="number"
                                          name="number_of_postal_sends"
                                          class="form-control"
                                          id="opt-conquetes-nb-postal"
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="number_of_postal_sends"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                    <div class="col">
                                      <div class="mb-3">
                                        <label
                                          for="ter_cib"
                                          data-bs-toggle="tooltip"
                                          title="Targeted Territory for Postal Sends"
                                          class="form-label"
                                        >
                                          {
                                            pageTranslations.formFields
                                              .conquestSection
                                              .targetedTerritory[language]
                                          }
                                        </label>
                                        <Field
                                          type="text"
                                          name="targeted_territory"
                                          class="form-control"
                                          id="ter_cib"
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                        />
                                        <ErrorMessage
                                          name="targeted_territory"
                                          component="div"
                                          className="error text-danger"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div class="row" style={{ paddingBottom: 10 }}>
                          <div
                            class={`collapse multi-collapse ${
                              isOpenAll ? "show" : ""
                            }`}
                            id="multiCollapseInfosvente"
                          >
                            <div
                              class="card card-body"
                              style={{
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            >
                              <h2
                                className={`${isDarkMode ? "darkthead" : ""}`}
                              >
                                {
                                  pageTranslations.formFields.salesInfoSection
                                    .title[language]
                                }
                              </h2>
                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="titre-de-la-vente"
                                      data-bs-toggle="tooltip"
                                      title="Sale Title"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.saletitle[language]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="event_title"
                                      class="form-control"
                                      id="titre-de-la-vente"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="event_title"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="vehicule-a-avant"
                                      data-bs-toggle="tooltip"
                                      title="Include the year and model. For microsites, web, banners, letters, etc."
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.featuredVehicle[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="featured_vehicle"
                                      class="form-control"
                                      id="vehicule-a-avant"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="featured_vehicle"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="coup-de-coeur"
                                      data-bs-toggle="tooltip"
                                      title="Favorites for vehicles to be featured"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.favorites[language]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="favorites"
                                      class="form-control"
                                      id="coup-de-coeur"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="favorites"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="langue-des-visuelles"
                                      data-bs-toggle="tooltip"
                                      title="Visual Language"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.visualLanguage[
                                          language
                                        ]
                                      }
                                    </label>

                                    <Field
                                      as="select"
                                      class={`form-select ${
                                        isDarkMode ? "custom-select" : ""
                                      }`}
                                      name="visual_language"
                                      aria-label="Default select example"
                                      id="langue-des-visuelles"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    >
                                      <option
                                        value=""
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="French"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations.commonWords.french[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="English"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations.commonWords.english[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="Bilingual"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations.commonWords.bilangual[
                                            language
                                          ]
                                        }
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="visual_language"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr />

                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="plage-hor-des-rdv"
                                      data-bs-toggle="tooltip"
                                      title="Appointment Time Slots"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.appointmentTS[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      as="select"
                                      class={`form-select ${
                                        isDarkMode ? "custom-select" : ""
                                      }`}
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                      name="appointment_time_slots"
                                      aria-label="Default select example"
                                      id="plage-hor-des-rdv"
                                    >
                                      <option
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {" "}
                                        {
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="30"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        30
                                      </option>
                                      <option
                                        value="60"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        60
                                      </option>
                                      {/* <option
                                        value="90"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        90
                                      </option> */}
                                      <option
                                        value="120"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        120
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="appointment_time_slots"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="plage-hor-des-rdv"
                                      data-bs-toggle="tooltip"
                                      title="Number of Appointments per Time Slot"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.appointmentsPTS[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      as="select"
                                      class={`form-select ${
                                        isDarkMode ? "custom-select" : ""
                                      }`}
                                      name="appointments_per_time_slot"
                                      aria-label="Default select example"
                                      id="rdv-par-plage-hor"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    >
                                      <option
                                        value=""
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="1"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        1
                                      </option>
                                      <option
                                        value="2"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        2
                                      </option>
                                      <option
                                        value="3"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        3
                                      </option>
                                      <option
                                        value="4"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        4
                                      </option>
                                      <option
                                        value="5"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        5
                                      </option>
                                      <option
                                        value="6"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        6
                                      </option>
                                      <option
                                        value="7"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        7
                                      </option>
                                      <option
                                        value="8"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        8
                                      </option>
                                      <option
                                        value="9"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        9
                                      </option>
                                      <option
                                        value="10"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        10
                                      </option>
                                      <option
                                        value="11"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        11
                                      </option>
                                      <option
                                        value="12"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        12
                                      </option>
                                      <option
                                        value="13"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        13
                                      </option>
                                      <option
                                        value="14"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        14
                                      </option>
                                      <option
                                        value="15"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        15
                                      </option>
                                      <option
                                        value="16"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        16
                                      </option>
                                      <option
                                        value="17"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        17
                                      </option>
                                      <option
                                        value="18"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        18
                                      </option>
                                      <option
                                        value="19"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        19
                                      </option>
                                      <option
                                        value="20"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        20
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="appointments_per_time_slot"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="heures-ouverture"
                                      data-bs-toggle="tooltip"
                                      title="Indicate the opening and closing hours of the dealership and the last appointment time for an entire week, from Monday to Sunday. Example: Monday - Friday: 10 am - 8 pm (LTA: 7 pm) | Saturday-Sunday: 10 am - 4 pm (LTA: 3 pm)"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.openingHoursWLA[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="opening_hours"
                                      class="form-control"
                                      id="heures-ouverture"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="opening_hours"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label
                                      for="ach-vec-occ"
                                      data-bs-toggle="tooltip"
                                      title="Are you a buyer of used vehicles without a new purchase or lease from the customer?"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.buyerOfUsedVehicles[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      as="select"
                                      class={`form-select ${
                                        isDarkMode ? "custom-select" : ""
                                      }`}
                                      name="new_purchase_or_lease"
                                      aria-label="Default select example"
                                      id="ach-vec-occ"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    >
                                      <option
                                        value=""
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="Yes"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {translations["dropdown"].yes[language]}
                                      </option>
                                      <option
                                        value="No"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {translations["dropdown"].no[language]}
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="new_purchase_or_lease"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="mb-3">
                                    <label
                                      for="ass-rdv"
                                      data-bs-toggle="tooltip"
                                      title="Do you want to assign your appointments to the customer's representative?"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.appointmentABS[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      as="select"
                                      class={`form-select ${
                                        isDarkMode ? "custom-select" : ""
                                      }`}
                                      name="assignment_by_seller"
                                      aria-label="Default select example"
                                      id="ass-rdv"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    >
                                      <option
                                        value=""
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="Assignment"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          pageTranslations.formFields
                                            .salesInfoSection.assignment[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="No Assignment"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          pageTranslations.formFields
                                            .salesInfoSection.noAssignment[
                                            language
                                          ]
                                        }
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="assignment_by_seller"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="accueille-les-clients"
                                      data-bs-toggle="tooltip"
                                      title="Who greets customers?"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection.greetsCustomers[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      as="select"
                                      class={`form-select ${
                                        isDarkMode ? "custom-select" : ""
                                      }`}
                                      name="greets_customers"
                                      aria-label="Default select example"
                                      id="accueille-les-clients"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    >
                                      <option
                                        value=""
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="Event Director"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"]
                                            .eventDirector[language]
                                        }
                                      </option>
                                      <option
                                        value="Dealership Employee"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"]
                                            .dealershipEmployee[language]
                                        }
                                      </option>
                                      <option
                                        value="Reception"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"].Reception[
                                            language
                                          ]
                                        }
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="greets_customers"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="nom-personne-accueil"
                                      data-bs-toggle="tooltip"
                                      title="The name of the person at the reception"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .salesInfoSection
                                          .nameOfpersonatReception[language]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="receptionist"
                                      class="form-control"
                                      id="nom-personne-accueil"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="receptionist"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* <hr
                                style={{
                                  borderTop: isDarkMode
                                    ? "1px solid #ffffff"
                                    : "1px solid #000000",
                                }}
                              /> */}

                              {false && (
                                <div class="row">
                                  <h4>Balloons showroom</h4>

                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="qte-ballon"
                                        data-bs-toggle="tooltip"
                                        title="Quantity (number of vehicles in the showroom)"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .salesInfoSection.quantity[language]
                                        }
                                      </label>
                                      <Field
                                        type="text"
                                        name="balloons_quantity"
                                        class="form-control"
                                        id="qte-ballon"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="balloons_quantity"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="clr-ballon"
                                        data-bs-toggle="tooltip"
                                        title="Colors of balloons for the showroom"
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .salesInfoSection.colors[language]
                                        }
                                      </label>
                                      <Field
                                        type="text"
                                        name="balloons_colors"
                                        class="form-control"
                                        id="clr-ballon"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="balloons_colors"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ paddingBottom: 10 }}>
                          <div
                            class={`collapse multi-collapse ${
                              isOpenAll ? "show" : ""
                            }`}
                            id="multiCollapseInfoscontact"
                          >
                            <div
                              class="card card-body"
                              style={{
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            >
                              <h2
                                className={`${isDarkMode ? "darkthead" : ""}`}
                              >
                                Contact
                              </h2>

                              <div class="row">
                                <h4>
                                  {
                                    pageTranslations.formFields.contactSection
                                      .themePromos[language]
                                  }
                                </h4>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="rep-theme-promos-nom"
                                      data-bs-toggle="tooltip"
                                      title="Name of the person in charge of the Theme and Promotions"
                                      class="form-label"
                                    >
                                      {
                                        translations["commonFields"].name[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="contact_tp_name"
                                      class="form-control"
                                      id="rep-theme-promos-nom"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="contact_tp_name"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="rep-theme-promos-courriel"
                                      data-bs-toggle="tooltip"
                                      title="Email of the person in charge of the Theme and Promotions"
                                      class="form-label"
                                    >
                                      {
                                        translations["commonFields"].email[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="contact_tp_email"
                                      class="form-control"
                                      id="rep-theme-promos-courriel"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="contact_tp_email"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="rep-theme-promos-tel"
                                      data-bs-toggle="tooltip"
                                      title="Phone of the person in charge of the Theme and Promotions"
                                      class="form-label"
                                    >
                                      {
                                        translations["commonFields"].phone[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="contact_tp_phone"
                                      class="form-control"
                                      id="rep-theme-promos-tel"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="contact_tp_phone"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <h4>
                                  {
                                    pageTranslations.formFields.contactSection
                                      .lists[language]
                                  }
                                </h4>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="rep-listes-nom"
                                      data-bs-toggle="tooltip"
                                      title="Name of the person in charge of the Lists"
                                      class="form-label"
                                    >
                                      {
                                        translations["commonFields"].name[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="contact_list_name"
                                      class="form-control"
                                      id="rep-listes-nom"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="contact_list_name"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="rep-listes-courriel"
                                      data-bs-toggle="tooltip"
                                      title="Email of the person in charge of the Lists"
                                      class="form-label"
                                    >
                                      {
                                        translations["commonFields"].email[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="contact_list_email"
                                      class="form-control"
                                      id="rep-listes-courriel"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="contact_list_email"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="rep-listes-tel"
                                      data-bs-toggle="tooltip"
                                      title="Phone of the person in charge of the Lists"
                                      class="form-label"
                                    >
                                      {
                                        translations["commonFields"].phone[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="contact_list_phone"
                                      class="form-control"
                                      id="rep-listes-tel"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="contact_list_phone"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" style={{ paddingBottom: 10 }}>
                          <div
                            class={`collapse multi-collapse ${
                              isOpenAll ? "show" : ""
                            }`}
                            id="multiCollapseInfosequipe"
                          >
                            <div
                              class="card card-body"
                              style={{
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            >
                              <h2
                                className={`${isDarkMode ? "darkthead" : ""}`}
                              >
                                {
                                  pageTranslations.formFields.teamSection.title[
                                    language
                                  ]
                                }
                              </h2>

                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="equipe-directeurs"
                                      data-bs-toggle="tooltip"
                                      title="Name of the Director(s)"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields.teamSection
                                          .directors[language]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="directors"
                                      class="form-control"
                                      id="equipe-directeurs"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="directors"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>

                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="equipe-rep"
                                      data-bs-toggle="tooltip"
                                      title="Name of the Representatives"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields.teamSection
                                          .representative[language]
                                      }
                                    </label>
                                    <Field
                                      type="text"
                                      name="representatives"
                                      class="form-control"
                                      id="equipe-rep"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="representatives"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row" style={{ paddingBottom: 10 }}>
                          <div
                            class={`collapse multi-collapse ${
                              isOpenAll ? "show" : ""
                            }`}
                            id="multiCollapseInfospromos"
                          >
                            <div
                              class="card card-body"
                              style={{
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            >
                              <h2
                                className={`${isDarkMode ? "darkthead" : ""}`}
                              >
                                {
                                  pageTranslations.formFields
                                    .promoAndRequestSection.title[language]
                                }
                              </h2>

                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="event-promos"
                                      data-bs-toggle="tooltip"
                                      title="Promotions for the event"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .promoAndRequestSection.promotions[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      as="textarea"
                                      type="text"
                                      name="promotions"
                                      class="form-control"
                                      id="event-promos"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    ></Field>
                                    <ErrorMessage
                                      name="promotions"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="event-comm"
                                      data-bs-toggle="tooltip"
                                      title="Comments for the event"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .promoAndRequestSection.comments[
                                          language
                                        ]
                                      }
                                    </label>
                                    <Field
                                      as="textarea"
                                      type="text"
                                      name="comments"
                                      class="form-control"
                                      id="event-comm"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    ></Field>
                                    <ErrorMessage
                                      name="comments"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="mb-3">
                                    <label
                                      for="modifSelection"
                                      data-bs-toggle="tooltip"
                                      title=""
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .promoAndRequestSection.q1[language]
                                      }
                                    </label>
                                    <Field
                                      as="textarea"
                                      type="text"
                                      name="modifications_instructions"
                                      class="form-control"
                                      id="modifSelection"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    />
                                    <ErrorMessage
                                      name="modifications_instructions"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-8">
                                  <div class="mb-3">
                                    <label
                                      for="modeleAnnPrio"
                                      data-bs-toggle="tooltip"
                                      title="Do you have specific models or years that you would like us to prioritize in the selection?"
                                      class="form-label"
                                    >
                                      {
                                        pageTranslations.formFields
                                          .promoAndRequestSection.q2[language]
                                      }
                                    </label>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <div class="mb-3">
                                    <Field
                                      as="select"
                                      class={`form-select ${
                                        isDarkMode ? "custom-select" : ""
                                      }`}
                                      name="models_or_years_selection"
                                      aria-label="Default select example"
                                      id="modeleAnnPrio"
                                      style={{
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "",
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#000000",
                                        border: isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6",
                                      }}
                                    >
                                      <option
                                        value=""
                                        selected=""
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                      </option>
                                      <option
                                        value="Yes"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {translations["dropdown"].yes[language]}
                                      </option>
                                      <option
                                        value="No"
                                        style={{
                                          background: isDarkMode
                                            ? "#1B1F22"
                                            : "",
                                          color: isDarkMode ? "#ffffff" : "",
                                        }}
                                      >
                                        {translations["dropdown"].no[language]}
                                      </option>
                                    </Field>
                                    <ErrorMessage
                                      name="models_or_years_selection"
                                      component="div"
                                      className="error text-danger"
                                    />
                                  </div>
                                </div>
                              </div>
                              {values.models_or_years_selection === "Yes" && (
                                <div class="row">
                                  <div class="col">
                                    <div class="mb-3">
                                      <label
                                        for="modeleAnnPrioOui"
                                        data-bs-toggle="tooltip"
                                        title=""
                                        class="form-label"
                                      >
                                        {
                                          pageTranslations.formFields
                                            .promoAndRequestSection.q3[language]
                                        }
                                      </label>
                                      <Field
                                        as="textarea"
                                        type="text"
                                        name="prioriti_reason"
                                        class="form-control"
                                        id="modeleAnnPrioOui"
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                        }}
                                      />
                                      <ErrorMessage
                                        name="prioriti_reason"
                                        component="div"
                                        className="error text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginBottom: "10px !important" }}
                          class="row"
                        >
                          <div
                            style={{ margin: "10px 0", paddingBottom: "10px" }}
                            class=""
                            id=""
                          >
                            <div class="card card-body docsection">
                              <h3
                                className={`${isDarkMode ? "darkthead" : ""}`}
                              >
                                Documents
                              </h3>
                              <table class="table table-hover text-center align-middle table-sm">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className={`${
                                        isDarkMode ? "darkthead" : ""
                                      }`}
                                    >
                                      #
                                    </th>
                                    <th
                                      scope="col"
                                      className={`${
                                        isDarkMode ? "darkthead" : ""
                                      }`}
                                    >
                                      {
                                        pageTranslations.formFields.week[
                                          language
                                        ]
                                      }
                                    </th>
                                    <th
                                      scope="col"
                                      className={`${
                                        isDarkMode ? "darkthead" : ""
                                      }`}
                                    >
                                      Type
                                    </th>
                                    <th
                                      scope="col"
                                      className={`${
                                        isDarkMode ? "darkthead" : ""
                                      }`}
                                    >
                                      {
                                        pageTranslations.formFields
                                          .documentSection.creationDate[
                                          language
                                        ]
                                      }
                                    </th>
                                    <th
                                      scope="col"
                                      className={`${
                                        isDarkMode ? "darkthead" : ""
                                      }`}
                                    >
                                      {
                                        pageTranslations.formFields
                                          .documentSection.createdBy[language]
                                      }
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody id="searchTableResults" class="">
                                  {documents &&
                                    documents.map((document) => (
                                      <tr class="result-box">
                                        <td class="docIdTable" scope="row">
                                          {document.id}
                                        </td>
                                        <td class="docWeekTable">
                                          {document.week}
                                        </td>
                                        <td class="docTypeTable">
                                          {document.type}
                                        </td>
                                        <td class="docCreatedDateTable">
                                          {document.formatted_created_at}
                                        </td>
                                        <td class="docCreatedByTable">
                                          {document.name}
                                        </td>
                                        <td>
                                          <a
                                            type="button"
                                            href={document.filelink}
                                            target="”_blank”"
                                            class="admin-btn btn-primary doc-download-table"
                                            id=""
                                          >
                                            <i class="bi bi-file-earmark-arrow-down"></i>{" "}
                                            {
                                              pageTranslations.formFields
                                                .documentSection.download[
                                                language
                                              ]
                                            }
                                          </a>
                                        </td>
                                        <td>
                                          {[
                                            "Pre Form",
                                            "GD Form",
                                            "Ca Form",
                                          ].includes(document.type) && (
                                            <button
                                              type="button"
                                              class="admin-btn btn-primary doc-send-table"
                                              id="FDD1816"
                                              data-docidtablecreated="1816"
                                              onClick={() => {
                                                sendDocument(
                                                  document.type
                                                    .toLowerCase()
                                                    .replace(/\s/g, "_"),
                                                  document.filelink,
                                                  document.week,
                                                  formValues.dealershipName
                                                );
                                              }}
                                            >
                                              <i class="bi bi-send"></i>{" "}
                                              {
                                                pageTranslations.formFields
                                                  .documentSection.send[
                                                  language
                                                ]
                                              }{" "}
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                              {/* <template id="rowdoctableTemplate">
                              <tr class="result-box">
                                <th
                                  style={{ color: "black !important" }}
                                  class="docIdTable"
                                  scope="row"
                                ></th>
                                <td
                                  style={{ color: "black !important" }}
                                  class="docWeekTable"
                                ></td>
                                <td
                                  style={{ color: "black !important" }}
                                  class="docTypeTable"
                                ></td>
                                <td
                                  style={{ color: "black !important" }}
                                  class="docCreatedDateTable"
                                ></td>
                                <td
                                  style={{ color: "black !important" }}
                                  class="docCreatedByTable"
                                ></td>
                                <td>
                                  <a
                                    type="button"
                                    href=""
                                    target="”_blank”"
                                    class="admin-btn btn-primary doc-download-table"
                                    id=""
                                  >
                                    <i class="bi bi-file-earmark-arrow-down"></i>{" "}
                                    Download
                                  </a>
                                </td>
                                <td>
                                  <button
                                    type="submit"
                                    class="admin-btn btn-primary doc-send-table"
                                  >
                                    <span
                                      id="spinner-doc-send"
                                      class="visually-hidden"
                                    >
                                      Loading...
                                    </span>
                                    <i class="bi bi-send"></i> Send{" "}
                                  </button>
                                </td>
                              </tr>
                            </template> */}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ marginBottom: "10px !important" }}
                          class="row"
                        >
                          <div style={{ margin: "10px 0" }} class="" id="">
                            <div class="card card-body docsection">
                              <h3
                                className={`${isDarkMode ? "darkthead" : ""}`}
                              >
                                {pageTranslations.clientForms[language]}
                              </h3>
                              <table class="table table-hover text-center align-middle table-sm">
                                <thead>
                                  <tr>
                                    <th
                                      scope="col"
                                      className={`${
                                        isDarkMode ? "darkthead" : ""
                                      }`}
                                    >
                                      #
                                    </th>
                                    <th
                                      scope="col"
                                      className={`${
                                        isDarkMode ? "darkthead" : ""
                                      }`}
                                    >
                                      {" "}
                                      {
                                        pageTranslations.formFields
                                          .documentSection.submittedOn[language]
                                      }{" "}
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody id="clientEntryTable" class="">
                                  {clientForms &&
                                    clientForms.map((form) => (
                                      <tr class="result-box">
                                        <td class="entryID" scope="row">
                                          {form.id}
                                        </td>
                                        <td class="submitedON">
                                          {form.submitted_on}
                                        </td>
                                        <td>
                                          <a
                                            type="button"
                                            href={form.drive_link}
                                            target="”_blank”"
                                            class="admin-btn btn-primary docFolder"
                                            id=""
                                          >
                                            <i class="bi bi-file-earmark-arrow-down"></i>{" "}
                                            Folder
                                          </a>
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            class="admin-btn btn-primary edit-client-form-button"
                                            data-customer-id="29"
                                            data-main-id="920"
                                            onClick={() => {
                                              setDisplayComponent(
                                                "edit-client"
                                              );
                                              window.history.pushState(
                                                {
                                                  id: "gallery",
                                                  randomData:
                                                    window.Math.random(),
                                                },
                                                "title",
                                                "/event-portal?page=edit-client&client_id=" +
                                                  form.id +
                                                  "&p=" +
                                                  editEventID
                                              ); // get state kpo bhi eekhna
                                            }}
                                          >
                                            <i class="bi bi-send"></i>{" "}
                                            {
                                              translations.commonWords.view[
                                                language
                                              ]
                                            }{" "}
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="footersec">
                        <div class="row">
                          <div class="col-sm">
                            <button
                              style={{ margin: "0 10px" }}
                              type="submit"
                              class="client-btn btn btn-success"
                              id="save-changes"
                              onClick={() => setSubmitPressed(true)}
                            >
                              <i class="bi bi-save"></i>{" "}
                              {translations["commonFields"].saveBtn[language]}
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              id="cancel-changes"
                              onClick={() => window.history.back()}
                              disabled={loading}
                            >
                              <i class="bi bi-x-circle"></i>{" "}
                              {translations["commonFields"].returnBtn[language]}{" "}
                            </button>
                            <div
                              style={{ margin: "0 10px" }}
                              class="btn-group"
                              role="group"
                            >
                              <button
                                type="button"
                                class="admin-btn btn btn-primary"
                                id="prod-form-FP"
                                data-bs-toggle="modal"
                                data-bs-target="#sendPdfModal"
                                disabled={loading}
                                onClick={() => {
                                  setFormType("pre_form");
                                  setWeek(values.week);
                                  saveChanges("edit-pre-form", values);
                                  // handleFormDownload("edit-pre-form",values);
                                }}
                              >
                                <i class="bi bi-file-earmark-text"></i> Form Pre
                              </button>
                              <button
                                type="button"
                                class="btn btn-light"
                                id="prod-form-DG"
                                data-bs-toggle="modal"
                                data-bs-target="#sendPdfModal"
                                disabled={loading}
                                onClick={() => {
                                  setFormType("gd_form");
                                  // handleFormDownload("edit-gd-form",values);
                                  setWeek(values.week);
                                  saveChanges("edit-gd-form", values);
                                }}
                              >
                                <i class="bi bi-file-earmark-text"></i> Form DG
                              </button>
                              <button
                                type="button"
                                class="admin-btn btn btn-primary"
                                id="prod-form-CC"
                                data-bs-toggle="modal"
                                data-bs-target="#sendPdfModal"
                                disabled={loading}
                                onClick={() => {
                                  setFormType("ca_form");
                                  // handleFormDownload("edit-ca-form",values);
                                  setWeek(values.week);
                                  saveChanges("edit-ca-form", values);
                                }}
                              >
                                <i class="bi bi-file-earmark-text"></i> Form CA
                              </button>
                            </div>
                            {values.first_day &&
                            values.days &&
                            values.event_dates &&
                            values.week ? (
                              <button
                                type="button"
                                class="client-btn btn btn-success"
                                id="send-form-modal"
                                data-bs-toggle="modal"
                                data-bs-target={"#sendFormModal"}
                                onClick={() => saveChanges("", values)}
                              >
                                <i class="bi bi-send"></i>{" "}
                                {
                                  pageTranslations.formFields.documentSection
                                    .send[language]
                                }{" "}
                              </button>
                            ) : (
                              <button
                                type="button"
                                class="client-btn btn btn-success"
                                disabled
                                onClick={() => saveChanges("", values)}
                              >
                                <i class="bi bi-send"></i>{" "}
                                {
                                  pageTranslations.formFields.documentSection
                                    .send[language]
                                }{" "}
                              </button>
                            )}

                            <a
                              style={{ margin: "0 10px" }}
                              type="button"
                              target="_blank"
                              class="btn btn-light drive-button"
                              id="evt-drive-link"
                              href={values.drive_link}
                              disabled={loading}
                            >
                              <i class="bi bi-folder"></i> Drive
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}

            {editEventID && (
              <SendFormModal
                token={token}
                EventID={editEventID}
                userEmails={userEmails}
                setIsLoading={setLoading}
                isDarkMode={isDarkMode}
              />
            )}

            {editEventID && (
              <SendPdfModal
                token={token}
                EventID={editEventID}
                formType={formType}
                formUrl={formUrl}
                driveLink={driveLink}
                week={week}
                setFormUrl={setFormUrl}
                dealer_name={formValues}
                isDarkMode={isDarkMode}
              />
            )}

            {/* <div id="myModal2" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div id="creationsuccess">
                  <div class="modal-header">
                    <h3 style={{ color: "#000000" }}>🎊 🎉 Document created</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                  </div>
                  <div class="modal-body">
                    <p>Your document has been created successfully. You can download it or send it by email by clicking on the appropriate button below (please select the recipient first).</p>
                    <div class="row">
                      <div class="mb-3">
                        <select class="form-select" id="conq-email">
                          <option></option>
                          <option>mmazraani@fidgi.ca</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                          <option>Sylvia.Dimitriou@data-axle.com</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: "71px" }} class="modal-footer" id="modalfooter">
                    <a type="button" href="" target="”_blank”" class="btn btn-primary" id="doc-download-conq">Download</a>
                    <button type="submit" class="btn btn-primary" id="doc-send-conq">
                      <span id="spinner-doc-send" class="visually-hidden">Loading...</span>
                      Submit the form
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <div class="row" style={{ width: "100%" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                        <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                        </symbol>
                        <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                        </symbol>
                        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                        </symbol>
                      </svg>
                      <div class="alert alert-danger d-flex align-items-center invisible" id="conqerroralert" role="alert">
                        <div id="conqerroralerttext">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditEventForm;
