import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import AddWeekModal from "./addWeekModal";
import Loader from "./loader";
import SalesOverview from "./components/salesOverview";
import ColumnChart from "./components/columnChart";
import StackedChart from "./components/stackedChart";
import numeral from "numeral";
import { MultiSelect } from "react-multi-select-component";
import { usePDF } from "react-to-pdf";
import LineChart from "./components/lineChart";
const SalesReport = ({
  setDisplayComponent,
  eventTrigger,
  setEditEventID,
  setIsDarkMode,
  isDarkMode,
  isLoading,
  setIsLoading,
  users,
  dealerships,
}) => {
  const { language } = useLanguage();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const statusOptions = [
    {
      label: translations.usersPage.createUser.statusOptions.active[language],
      value: "Active",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.cancelled[language],
      value: "Cancelled",
    },
  ];
  const token = localStorage.getItem("token");
  const [events, setEvents] = useState(null);
  const [eventsSearch, setEventsSearch] = useState();
  const [editID, seteditID] = useState();
  const [pageCache, setPageCache] = useState({});
  const [searchCache, setSearchCache] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [total, setTotal] = useState();
  const [activeSearch, setActiveSearch] = useState(false);
  const [key, setKey] = useState("");
  const [eventId, setEventId] = useState("");
  const [parentId, setParentId] = useState("");
  const [week, setWeek] = useState("");
  const [loading, setLoading] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [deleteStates, setDeleteStates] = useState();
  const [oldData, setOldData] = useState(null);
  const [graphData, setgraphData] = useState("");
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedQuarters, setSelectedQuarters] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const { toPDF, targetRef } = usePDF({ filename: "Sales_Report.pdf" });

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (pageCache[pageNo]) {
        // Data already in cache, no need for API call
        const { cachedResponse, cachedTotal } = pageCache[pageNo];
        setEvents(cachedResponse);
        setEventsSearch(cachedResponse);
        setTotal(cachedTotal);
      } else {
        try {
          setLoading(true);
          const res = await fetch(
            ApiUrl + "/api/user/private-events-report?page=" + pageNo,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          // console.log("Get Events res: ", res);
          const data = await res.json();
          // console.log("Get Events data: ", data)
          // console.log(result)
          if (data.status === "success") {
            const newData = data.private_events.data;
            const newTotal = data.private_events.total;

            // Update the cache with new data
            // Set state with new data
            setEvents(newData);
            setEventsSearch(newData);
            setTotal(newTotal);
            setPageCache((prevCache) => ({
              ...prevCache,
              [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
            }));

            setDeleteStates(
              newData &&
                Object.keys(newData).map((key) =>
                  newData[key].map((item) => ({ [item.id]: false }))
                )
            );
          } else {
            toast.error(data.message);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toast.error("Something went wrong");
        } finally {
        }
      }
    };

    if (!activeSearch && token) {
      fetchData();
    }
    const userData = JSON.parse(localStorage.getItem("userData"));
    setIsDarkMode(userData.theme_mode !== "light" ? true : false);
  }, [pageNo, pageCache, events, eventTrigger]);

  useEffect(() => {
    if (eventTrigger) {
      const newData = eventTrigger.data;
      const newTotal = eventTrigger.total;

      // Update the cache with new data
      setPageCache((prevCache) => ({
        ...prevCache,
        [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
      }));
      // Set state with new data
      setEvents(newData);
      setEventsSearch(newData);
      setTotal(newTotal);
    }
  }, [eventTrigger]);

  const handleCopy = async () => {
    const page =
      events.length > 1 ? pageNo : pageNo - 1 > 0 ? pageNo - 1 : pageNo;
    const searchParam = key ? "&search_parameter=" + key : "";
    try {
      setLoading(true);
      const res = await fetch(
        ApiUrl + "/api/user/copy-parentform?page=" + page + "" + searchParam,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            parent_id: parentId,
            // week: week,
          }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        setDisplayComponent("edit-form");
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          `/event-portal?page=edit-form&event_id=${eventId}&form_id=${data.event_url}`
        );
        // toast.success("Event copied successfully");
        // setDisplayComponent("edit-event");
        // window.history.pushState(
        //   {
        //     id: "gallery",
        //     randomData: window.Math.random(),
        //   },
        //   "title",
        //   "/event-portal?page=edit-event&parent_id=" + data.event_url
        // );
        // const newData = data.event_list.data;
        // const newTotal = data.event_list.total;
        // setEvents(newData);
        // setTotal(newTotal);
        // if (key) {
        //   setSearchCache({
        //     [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        //   });
        // } else {
        //   setPageCache({
        //     [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
        //   });
        // }
        // if (activeSearch) {
        //   setPageNo(
        //     events && Object.keys(events).length > 1
        //       ? pageNo
        //       : pageNo - 1 > 0
        //       ? pageNo - 1
        //       : pageNo
        //   );
        // }
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  const handleSearch = async (pageNo, initial) => {
    if (!initial && searchCache[pageNo]) {
      // Data already in cache, no need for API call
      const { cachedResponse, cachedTotal } = searchCache[pageNo];
      setEvents(cachedResponse);
      setTotal(cachedTotal);
    } else {
      // setFilterLoading(true);
      // setGetLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/private-events-report/" + key + "?page=" + pageNo,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setActiveSearch(true);

          const newData = data.private_events.data;
          const newTotal = data.private_events.total;
          // Update the cache with new data
          setSearchCache((prevCache) => ({
            ...prevCache,
            [pageNo]: { cachedResponse: newData, cachedTotal: newTotal },
          }));
          // Set state with new data
          setEvents(newData);
          setEventsSearch(newData);
          setTotal(newTotal);
          // if (pageNo === 1) toast.success("Search successful");
          // setFilterLoading(false);
          // setGetLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        toast.error("Something went wrong");
        // setFilterLoading(false);
        // setGetLoading(false);
      }
    }
  };

  const filterData = async (months, status) => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/ghraph-objective", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status: status, months: months }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
        setgraphData(data);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageCache((prevCache) => ({
        ...prevCache,
        pageNo: pageNo,
      }));
      setPageNo(1);
      setSearchCache({});
      handleSearch(1, true);
    }, 500);

    return () => clearTimeout(timer);
  }, [key]);

  useEffect(() => {
    const getGraphData = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/ghraph-objective", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          setgraphData(data);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching brands", error);
        // Handle error or show a toast if needed
      }
    };
    getGraphData();
  }, []);

  const objData = [
    {
      name: "OBJ",
      data:
        graphData.objectiveVendors &&
        graphData.objectiveVendors.length > 0 &&
        graphData.objectiveVendors.map((item) => item.Obj),
      color: "#6E90ED",
    },
    {
      name: translations.salesReport.vral[language],
      data:
        graphData.objectiveVendors &&
        graphData.objectiveVendors.length > 0 &&
        graphData.objectiveVendors.map((item) => item.Real),
      color: "#34DBB4",
    },
  ];

  const actifCancelledData = [
    {
      name: translations.salesReport.active[language],
      data:
        graphData.active_CancelledVendors &&
        graphData.active_CancelledVendors.length > 0 &&
        graphData.active_CancelledVendors.map((item) => item.Active),
      color: "#34DBB4",
    },
    {
      name: translations.salesReport.cancelled[language],
      data:
        graphData.active_CancelledVendors &&
        graphData.active_CancelledVendors.length > 0 &&
        graphData.active_CancelledVendors.map((item) => item.Cancelled),
      color: "#EF1917",
    },
  ];

  const colors = ["#6E90ED", "#34DBB4", "#F07584", "#F49E57", "#F2C35F"]; // Predefined color array

  const representativesData =
    graphData.monthlySalesData && graphData.monthlySalesData.length > 0
      ? graphData.monthlySalesData.map((data, index) => ({
          name: data.vendorname, // Assuming 'representativeName' is the key for the name
          data: [
            data.months.jan || 0,
            data.months.feb || 0,
            data.months.mar || 0,
            data.months.apr || 0,
            data.months.may || 0,
            data.months.jun || 0,
            data.months.jul || 0,
            data.months.aug || 0,
            data.months.sep || 0,
            data.months.oct || 0,
            data.months.nov || 0,
            data.months.dec || 0,
          ], // Assuming 'salesData' is the key for the array of sales numbers
          color: colors[index % colors.length], // Assign colors based on index
        }))
      : [];

  // Define an array of month keys
  const monthKeys = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  const monthKeys2 = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  const monthOptions = [
    { value: "1", label: `Jan  ${new Date().getFullYear()}` },
    { value: "2", label: `Feb  ${new Date().getFullYear()}` },
    { value: "3", label: `Mar  ${new Date().getFullYear()}` },
    { value: "4", label: `Apr  ${new Date().getFullYear()}` },
    { value: "5", label: `May  ${new Date().getFullYear()}` },
    { value: "6", label: `Jun ${new Date().getFullYear()}` },
    { value: "7", label: `Jul  ${new Date().getFullYear()}` },
    { value: "8", label: `Aug  ${new Date().getFullYear()}` },
    { value: "9", label: `Sep  ${new Date().getFullYear()}` },
    { value: "10", label: `Oct  ${new Date().getFullYear()}` },
    { value: "11", label: `Nov  ${new Date().getFullYear()}` },
    { value: "12", label: `Dec  ${new Date().getFullYear()}` },
  ];

  const quarterOptions = [
    { value: "q1", label: `Q1  ${new Date().getFullYear()}` },
    { value: "q2", label: `Q2  ${new Date().getFullYear()}` },
    { value: "q3", label: `Q3  ${new Date().getFullYear()}` },
    { value: "q4", label: `Q4  ${new Date().getFullYear()}` },
  ];

  const quarterToMonths = {
    q1: monthKeys.slice(0, 3), // ["jan", "feb", "mar"]
    q2: monthKeys.slice(3, 6), // ["apr", "may", "jun"]
    q3: monthKeys.slice(6, 9), // ["jul", "aug", "sep"]
    q4: monthKeys.slice(9, 12), // ["oct", "nov", "dec"]
  };

  const totalMonthlySales = monthKeys.reduce((totals, month) => {
    totals[month] = representativesData.reduce(
      (sum, item) => sum + (item.data[monthKeys.indexOf(month)] || 0),
      0
    );
    return totals;
  }, {});

  return (
    <div className="Events">
      {!loading ? (
        <>
          <div className="container maincont">
            <div id="app" className="appcountainer2">
              <div className="row align-items-center">
                <div className="col-9">
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.salesReport.title[language]}
                  </h2>
                </div>
                {/* <div className="col-3 d-flex">
                  <Select
                    className="selectpicker w-100"
                    aria-labelledby="dealership-name-quick"
                    // isClearable
                    options={filterOptions}
                    placeholder={translations["dropdown"].select[language]}
                    isSearchable={false}
                    value={
                      (filterOptions &&
                        filterOptions.length > 0 &&
                        filterOptions.find(
                          (option) => option.value === filterOption
                        )) || {
                        value: "",
                        label: translations["dropdown"].select[language],
                      }
                    }
                    onChange={(selectedOption) => {
                      setFilterOption(selectedOption.value);
                      filterEvents(selectedOption.value);
                    }}
                    // maxMenuHeight={"120px"}
                    styles={{
                      input: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        backgroundColor: isDarkMode ? "transparent" : "white",
                        border: state.isFocused
                          ? "1px solid #80bdff"
                          : isDarkMode
                          ? "1px solid #495057"
                          : "1px solid #dee2e6", // Adjust the border color for focus
                        boxShadow: state.isFocused
                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                          : "none", // Bootstrap's box shadow for focus
                        borderRadius: ".45rem",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: "none", // Hide the indicator separator
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                        color: isDarkMode ? "#ffffff" : "#868686",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: isDarkMode ? "#1f2023" : "white",
                        margin: "0", // Remove default margin
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                        // Additional styles for placeholder
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        color: isDarkMode ? "#ffffff" : "black",
                        backgroundColor: state.isHovered
                          ? "#80bdff"
                          : isDarkMode
                          ? "#1f2023"
                          : "white",
                        ":hover": {
                          backgroundColor: isDarkMode ? "#34DBB4" : "#34DBB4",
                        },
                      }),
                    }}
                  />
                </div> */}
              </div>
              {/* <div
                className="row align-items-center"
                style={{ minHeight: "44px" }}
              >
                <div className="col-3">
                  {selectedWeeks && selectedWeeks.length > 0 && (
                    <button
                      type="button"
                      className="client-btn d-flex align-items-center gap-2"
                      onClick={exportToExcel}
                    >
                      <i className="bi bi-upload"></i>
                      {translations.commonWords.export[language]}{" "}
                    </button>
                  )}
                </div>
              </div> */}
              {
                <div className="headersec row my-3">
                  <div className="col-12 d-flex justify-content-end gap-2 align-items-end">
                    <div className="col-2">
                      <label class="form-label">
                        {translations.salesReport.filterMonth[language]}
                      </label>
                      <MultiSelect
                        options={monthOptions}
                        className={`selectpicker w-100 ${
                          isDarkMode ? "reactMultiDark" : ""
                        }`}
                        value={selectedMonths}
                        onChange={(selected) => {
                          setSelectedQuarters([]);
                          setSelectedMonths(selected);
                          const months = selected.map((option) => option.value);
                          filterData(months, selectedStatus[0]?.value || "");
                        }}
                        disableSearch
                        labelledBy={translations["dropdown"].select[language]}
                      />
                    </div>
                    <div className="col-2">
                      <label class="form-label">
                        {translations.salesReport.filterQuarter[language]}
                      </label>
                      <MultiSelect
                        options={quarterOptions}
                        className={`selectpicker w-100 ${
                          isDarkMode ? "reactMultiDark" : ""
                        }`}
                        value={selectedQuarters}
                        onChange={(selected) => {
                          setSelectedMonths([]);
                          setSelectedQuarters(selected);
                          const selectedQuarterValues = selected.map(
                            (option) => option.value
                          );
                          const months = [];

                          selectedQuarterValues.forEach((quarter) => {
                            if (quarterToMonths[quarter]) {
                              months.push(...quarterToMonths[quarter]);
                            }
                          });
                          filterData(months, selectedStatus[0]?.value || "");
                        }}
                        disableSearch
                        labelledBy={translations["dropdown"].select[language]}
                      />
                    </div>
                    <div className="col-2">
                      <label class="form-label">Status</label>
                      <MultiSelect
                        options={statusOptions}
                        className={`selectpicker w-100 ${
                          isDarkMode ? "reactMultiDark" : ""
                        }`}
                        value={selectedStatus}
                        hasSelectAll={false}
                        onChange={(selected) => {
                          setSelectedStatus(
                            selected.length
                              ? [selected[selected.length - 1]]
                              : []
                          );
                          let months = [];

                          if (selectedMonths.length > 0) {
                            months = selectedMonths.map(
                              (option) => option.value
                            );
                          }
                          if (selectedQuarters.length > 0) {
                            const selectedQuarterValues = selectedQuarters.map(
                              (option) => option.value
                            );
                            selectedQuarterValues.forEach((quarter) => {
                              if (quarterToMonths[quarter]) {
                                months.push(...quarterToMonths[quarter]);
                              }
                            });
                          }
                          filterData(
                            months.length > 0 ? months : "",
                            selected.length
                              ? selected[selected.length - 1]?.value
                              : ""
                          );
                        }}
                        disableSearch
                        labelledBy={translations["dropdown"].select[language]}
                      />
                    </div>

                    <div className="">
                      {" "}
                      <button
                        type="button"
                        className="admin-btn d-flex align-items-center gap-2"
                        onClick={toPDF}
                      >
                        <i className="bi bi-download"></i>
                        {translations.commonWords.downloadPDF[language]}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              }

              <div ref={targetRef}>
                <SalesOverview graphData={graphData} isDarkMode={isDarkMode} />
                <div
                  className=" rounded-1 my-4"
                  style={{
                    boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
                    // minHeight: "250px",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #dee2e6" }}>
                    <p className="py-3 fw-bold ps-2">
                      {translations.salesReport.realvsObjective[language]}{" "}
                    </p>
                  </div>
                  <div>
                    <ColumnChart
                      data={objData}
                      titleX={translations.viewBillingPage.seller[language]}
                      titleY={translations.salesReport.totalAmount[language]}
                      isDarkMode={isDarkMode}
                      vendors={
                        graphData.objectiveVendors &&
                        graphData.objectiveVendors.length > 0 &&
                        graphData.objectiveVendors.map((item) => item.name)
                      }
                    />
                  </div>
                </div>

                <div
                  className=" rounded-1 my-4"
                  style={{
                    boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
                    // minHeight: "250px",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #dee2e6" }}>
                    <p className="py-3 fw-bold ps-2">
                      {translations.salesReport.activeVScancelled[language]}{" "}
                    </p>
                  </div>
                  <div>
                    <ColumnChart
                      data={actifCancelledData}
                      isDarkMode={isDarkMode}
                      titleX={translations.salesReport.sellerModified[language]}
                      titleY={translations.salesReport.totalAmount[language]}
                      vendors={
                        graphData.active_CancelledVendors &&
                        graphData.active_CancelledVendors.length > 0 &&
                        graphData.active_CancelledVendors.map(
                          (item) => item.name
                        )
                      }
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div
                    className=" rounded-1 my-2 w-50"
                    style={{
                      boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
                      // minHeight: "250px",
                    }}
                  >
                    <div>
                      <ColumnChart
                        data={[
                          {
                            name: "OBJ",
                            data:
                              graphData.ActiveCancelledVendors &&
                              graphData.ActiveCancelledVendors.length > 0 &&
                              graphData.ActiveCancelledVendors.map(
                                (item) => item.ActiveCancelled
                              ),
                            color: "#6E90ED",
                          },
                        ]}
                        isDarkMode={isDarkMode}
                        titleX={
                          translations.salesReport.sellerModified[language]
                        }
                        titleY={translations.salesReport.totalAmount[language]}
                        vendors={
                          graphData.ActiveCancelledVendors &&
                          graphData.ActiveCancelledVendors.length > 0 &&
                          graphData.ActiveCancelledVendors.map(
                            (item) => item.name
                          )
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="w-50 rounded-1 my-2 p-1"
                    style={{
                      boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
                      // minHeight: "250px",
                    }}
                  >
                    <table
                      className="table align-content-center"
                      style={{ border: "1px solid #CBCBCB" }}
                    >
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            rowSpan={2}
                            className={`${
                              isDarkMode
                                ? "darkthead text-center"
                                : "text-center"
                            }`}
                            style={{
                              background: isDarkMode
                                ? "transparent"
                                : "#F2F2F2",
                              borderRight: "1px solid #CBCBCB",
                            }}
                          ></th>
                          <th
                            scope="col"
                            className={`${
                              isDarkMode
                                ? "darkthead text-center"
                                : "text-center"
                            }`}
                            style={{
                              background: isDarkMode
                                ? "transparent"
                                : "#E2E2E2",
                              borderRight: "1px solid #CBCBCB",
                            }}
                          >
                            {translations.salesReport.sellerModified[language]}
                          </th>
                          <th
                            scope="col"
                            className={`${
                              isDarkMode
                                ? "darkthead text-center"
                                : "text-center"
                            }`}
                            style={{
                              background: isDarkMode
                                ? "transparent"
                                : "#E2E2E2",
                              borderRight: "1px solid #CBCBCB",
                            }}
                          >
                            Total{" "}
                            {translations.forms.formFields.amount[language]}{" "}
                            total
                          </th>
                          <th
                            scope="col"
                            className={`${
                              isDarkMode
                                ? "darkthead text-center"
                                : "text-center"
                            }`}
                            style={{
                              background: isDarkMode
                                ? "transparent"
                                : "#E2E2E2",
                            }}
                          >
                            ID Count
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {graphData.ActiveCancelledVendors &&
                          graphData.ActiveCancelledVendors.length > 0 &&
                          graphData.ActiveCancelledVendors.map(
                            (item, index) => (
                              <tr key={index}>
                                <td
                                  className="dlrname py-2"
                                  style={{
                                    background: isDarkMode
                                      ? "transparent"
                                      : "#F2F2F2",
                                    borderRight: "1px solid #CBCBCB",
                                  }}
                                >
                                  {index + 1}.
                                </td>
                                <td
                                  className="dlrname py-2"
                                  style={{
                                    background: isDarkMode
                                      ? "transparent"
                                      : "#F2F2F2",
                                    borderRight: "1px solid #CBCBCB",
                                  }}
                                >
                                  {item?.name}
                                </td>
                                <td
                                  className="dlrname py-2"
                                  style={{
                                    borderRight: "1px solid #CBCBCB",
                                  }}
                                >
                                  CAD{" "}
                                  {numeral(item.ActiveCancelled).format(
                                    "0,000.00"
                                  )}
                                </td>
                                <td className="text-end py-2">
                                  {item?.count}{" "}
                                </td>
                              </tr>
                            )
                          )}

                        <tr>
                          <td
                            className="dlrname py-2"
                            style={{
                              background: isDarkMode
                                ? "transparent"
                                : "#F2F2F2",
                              borderRight: "1px solid #CBCBCB",
                            }}
                          ></td>
                          <td
                            className="dlrname py-2 fw-bold"
                            style={{
                              borderRight: "1px solid #CBCBCB",
                              background: isDarkMode ? "transparent" : "#F2F2F2",
                            }}
                          >
                            {translations.salesReport.generalSummary[language]}
                          </td>
                          <td className="dlrname py-2 fw-bold">
                            CAD{" "}
                            {numeral(
                              graphData.ActiveCancelledVendors &&
                                graphData.ActiveCancelledVendors.length > 0 &&
                                graphData.ActiveCancelledVendors.reduce(
                                  (acc, item) => acc + item.ActiveCancelled,
                                  0
                                )
                            ).format("0,000.00")}
                          </td>

                          <td className="text-end py-2 fw-bold">
                            {graphData.ActiveCancelledVendors &&
                              graphData.ActiveCancelledVendors.length > 0 &&
                              graphData.ActiveCancelledVendors.reduce(
                                (acc, item) => acc + item.count,
                                0
                              )}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className=" rounded-1 my-4"
                  style={{
                    boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
                    // minHeight: "250px",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #dee2e6" }}>
                    <p className="py-3 fw-bold ps-2">
                      {translations.salesReport.monthlyDistributions[language]}{" "}
                    </p>
                  </div>
                  <div>
                    <StackedChart
                      isDarkMode={isDarkMode}
                      data={representativesData}
                      titleX={translations.salesReport.monthYearGroup[language]}
                      titleY={
                        "Total " +
                        translations.forms.formFields.amount[language] +
                        " total"
                      }
                    />
                  </div>
                </div>
                <div
                  className="rounded-1 my-2 p-1"
                  style={{
                    boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
                    // minHeight: "250px",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #dee2e6" }}>
                    <p className="py-3 fw-bold ps-2">
                      {translations.salesReport.ObjectivesandSales[language]}{" "}
                    </p>
                  </div>
                  <table
                    className="table align-content-center pt-2"
                    style={{ border: "1px solid #CBCBCB" }}
                  >
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          rowSpan={2}
                          className={`${
                            isDarkMode ? "darkthead text-center" : "text-center"
                          }`}
                          style={{
                            background: isDarkMode ? "transparent" : "#F2F2F2",
                            borderRight: "1px solid #CBCBCB",
                          }}
                        ></th>
                        <th
                          scope="col"
                          className={`${
                            isDarkMode ? "darkthead text-center" : "text-center"
                          }`}
                          style={{
                            background: isDarkMode ? "transparent" : "#E2E2E2",
                            borderRight: "1px solid #CBCBCB",
                          }}
                        >
                          {translations.viewBillingPage.seller[language]}
                        </th>
                        {[...Array(12)].map((_, index) => (
                          <th
                            key={index}
                            scope="col"
                            className={`${
                              isDarkMode
                                ? "darkthead text-center"
                                : "text-center"
                            }`}
                            style={{
                              background: isDarkMode
                                ? "transparent"
                                : "#E2E2E2",
                              borderRight: "1px solid #CBCBCB",
                            }}
                          >
                            {index + 1}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {graphData &&
                        graphData.monthlySalesData.length > 0 &&
                        graphData.monthlySalesData.map((item, index) => (
                          <tr key={index}>
                            <td
                              className="dlrname py-2"
                              style={{
                                background: isDarkMode
                                  ? "transparent"
                                  : "#F2F2F2",
                                borderRight: "1px solid #CBCBCB",
                              }}
                            >
                              {index + 1}.
                            </td>
                            <td
                              className="dlrname py-2"
                              style={{
                                background: isDarkMode
                                  ? "transparent"
                                  : "#F2F2F2",
                                borderRight: "1px solid #CBCBCB",
                              }}
                            >
                              {item?.vendorname}
                            </td>
                            {monthKeys2.map((month, i) => {
                              // Retrieve the value for the current month, defaulting to 0 if not available
                              const value = item.months[month] || "";

                              return (
                                <td
                                  className="dlrname py-2"
                                  style={{
                                    borderRight: "1px solid #CBCBCB",
                                  }}
                                  key={i}
                                >
                                  {value
                                    ? "CAD " + numeral(value).format("0,000.00")
                                    : ""}
                                </td>
                              );
                            })}
                          </tr>
                        ))}

                      <tr>
                        <td
                          className="dlrname py-2"
                          style={{
                            background: isDarkMode ? "transparent" : "#F2F2F2",
                            borderRight: "1px solid #CBCBCB",
                          }}
                        ></td>
                        <td
                          className="dlrname py-2 fw-bold"
                          style={{
                            borderRight: "1px solid #CBCBCB",
                            background: isDarkMode ? "transparent" : "#F2F2F2",
                          }}
                        >
                          {translations.salesReport.generalSummary[language]}
                        </td>
                        {monthKeys.map((month, monthIndex) => (
                          <td
                            key={monthIndex}
                            className="dlrname py-2 fw-bold"
                            style={{
                              borderRight: "1px solid #CBCBCB",
                            }}
                          >
                            CAD{" "}
                            {numeral(totalMonthlySales[month] || 0).format(
                              "0,000.00"
                            )}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className=" rounded-1 my-4"
                  style={{
                    boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
                    // minHeight: "250px",
                  }}
                >
                  <div style={{ borderBottom: "1px solid #dee2e6" }}>
                    <p className="py-3 fw-bold ps-2">
                      {translations.salesReport.salesDistribution[language]}{" "}
                    </p>
                  </div>
                  <div>
                    <LineChart
                      isDarkMode={isDarkMode}
                      data={representativesData}
                      titleX={translations.salesReport.monthYearGroup[language]}
                      titleY={
                        "Total " +
                        translations.forms.formFields.amount[language] +
                        " total"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AddWeekModal
            handleCopy={handleCopy}
            eventId={eventId}
            week={week}
            setWeek={setWeek}
            setEventId={setEventId}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SalesReport;
