import React from "react";
import Chart from "react-apexcharts";

const LineChart = ({ data, isDarkMode, titleX, titleY }) => {
  var options = {
    series: data,
    chart: {
      type: "line",
      height: 450, // Change this to false to display bars in parallel
      toolbar: {
        show: false, // Hide the download button
      },
    //   dropShadow: {
    //     enabled: true,
    //     color: "#000",
    //     top: 18,
    //     left: 7,
    //     blur: 10,
    //     opacity: 0.2,
    //   },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: true,

      formatter: function (value, { seriesIndex }) {
        return `CAD ${value?.toLocaleString()}`;
      },
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
        colors: [isDarkMode ? "" : "#373d3f"],
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: [
        "Jan 2024",
        "Feb 2024",
        "Mar 2024",
        "Apr 2024",
        "May 2024",
        "Jun 2024",
        "Jul 2024",
        "Aug 2024",
        "Sep 2024",
        "Oct 2024",
        "Nov 2024",
        "Dec 2024",
      ],
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: isDarkMode
            ? [
                "Jan 2024",
                "Feb 2024",
                "Mar 2024",
                "Apr 2024",
                "May 2024",
                "Jun 2024",
                "Jul 2024",
                "Aug 2024",
                "Sep 2024",
                "Oct 2024",
                "Nov 2024",
                "Dec 2024",
              ].map(() => {
                return "#ffffff";
              })
            : [""],
        },
        // formatter: (val) => `${val} \nVendor`,
      },
      title: {
        text: titleX,
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: isDarkMode ? "#ffffff" : "",
        },
      },
    },
    yaxis: {
      //   min: 0, // Set a minimum value slightly below the lowest bar to create space
      //   max: Math.max(...data.flatMap((series) => series.data)) * 1.1,
      labels: {
        formatter: (value) => `CAD ${value?.toLocaleString()}`,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: [isDarkMode ? "#ffffff" : "#373d3f"],
        },
      },
      title: {
        text: titleY,
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: isDarkMode ? "#ffffff" : "",
        },
      },
    },
    legend: {
      position: "bottom", // Position the legend on the right
      horizontalAlign: "center",
      offsetY: 10,
      offsetX: -20,
      labels: {
        useSeriesColors: true,
      },
    //   markers: {
    //     width: 12,
    //     height: 12,
    //     radius: 0,
    //   },
      itemMargin: {
        vertical: 5,
      },
      onItemClick: {
        toggleDataSeries: true, // Enable checkbox-like behavior to toggle series
      },
    },
    tooltip: {
      y: {
        formatter: (value) => `CAD ${value?.toLocaleString()}`,
      },
      theme: isDarkMode ? "dark" : "light", // Set tooltip theme
    },
    plotOptions: {
      bar: {
        columnWidth: "70%", // Adjust the width of the bars
      },
    },
  };

  return (
    <div className="my-4 w-100" style={{ maxHeight: "700px" }}>
      <div className="row w-100">
        <div className="mixed-chart w-100">
          <Chart
            options={options}
            series={options.series}
            type="line"
            // width="100%"
            height="620"
          />
        </div>
      </div>
    </div>
  );
};

export default LineChart;
