import React, { useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import Select from "react-select";
import { toast } from "react-toastify";
import { ApiUrl } from "../ApiUrl";
const FilterBar = ({
  isDarkMode,
  checkedFields,
  setCheckedFields,
  allfields,
  textOptions,
  textOptions2,
  filterOption,
  setFilterOption,
  dealerOptions,
  repOptions2,
  managers,
  packageOptions,
  animatorOptions,
  getFilteredEvents,
  weekOptions,
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");
  const [filtersData, setFiltersData] = useState([]);
  const button = document.getElementById("filterClose");
  const addFilter = async (field) => {
    // setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/add-fillter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(field),
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };
  const [weekRange, setWeekRange] = useState({ range1: "", range2: "" });

  return (
    <div
      className={`offcanvas offcanvas-end offcanvas-sm h-100 ${
        isDarkMode ? "bg-dark" : "bg-body-tertiary"
      } `}
      tabindex="-1"
      id="filtersidebar"
      data-bs-backdrop="true"
      aria-bs-labelledby="offcanvasmainLabel"
      // style={{minHeight:"100vh"}}
    >
      <div className="offcanvas-body flex-column" style={{ height: "100vh" }}>
        <div
          className="d-flex px-4 pt-4 justify-content-between align-items-center "
          style={{
            borderBottom: "1px solid #dee2e6",
          }}
        >
          <h3>{translations.commonWords.search[language]} </h3>
          <button
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id="filterClose"
            style={{
              background: "transparent",
              color: isDarkMode ? "white" : "black",
              border: "none",
              fontWeight: "400",
              fontSize: "18px",
            }}
          >
            X
          </button>
        </div>
        <div
          className=""
          style={{ maxHeight: "calc(100vh - 147px", overflowY: "auto" }}
        >
          <ul
            className={`nav flex-column gap-2 px-4 ${
              checkedFields && checkedFields.length > 0 ? "mt-4" : "mt-4"
            } pb-4 `}
          >
            {allfields &&
              allfields.map((data1, index) => (
                <li className="py-1" key={index}>
                  <div className="d-flex gap-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={checkedFields.some(
                        (field) => field.name === data1.name
                      )}
                      id={data1.name}
                      name={data1.name}
                      onChange={(e) => {
                        if (e.target.checked) {
                          // Check if the type is not "date"
                          if (data1.type !== "date") {
                            setCheckedFields([
                              ...checkedFields,
                              {
                                name: data1.name,
                                content: data1.content,
                                type: data1.type,
                                value: "",
                                filterType: "",
                              },
                            ]);
                            addFilter({ [data1.name]: "1" });
                          } else {
                            // Handle date type
                            if (data1.name === "end_date") {
                              // Ensure startDate is selected before allowing endDate
                              if (
                                checkedFields.some(
                                  (field) => field.name === "start_date"
                                )
                              ) {
                                setCheckedFields([
                                  ...checkedFields,
                                  {
                                    name: data1.name,
                                    content: data1.content,
                                    type: data1.type,

                                    filterType: "",
                                    value: "",
                                  },
                                ]);
                                addFilter({ [data1.name]: "1" });
                              } else {
                                // Show error message if startDate is not selected
                                toast.error(
                                  translations.commonFields.queryFilters
                                    .endDateError[language]
                                );
                                e.target.checked = false; // Uncheck the endDate checkbox
                              }
                            } else {
                              // Handle startDate selection
                              setCheckedFields([
                                ...checkedFields,
                                {
                                  name: data1.name,
                                  content: data1.content,
                                  type: data1.type,
                                  value: "",
                                  filterType: "",
                                },
                              ]);
                              addFilter({ [data1.name]: "1" });
                            }
                          }
                        } else {
                          // Remove the object with the matching name
                          setCheckedFields(
                            checkedFields.filter(
                              (field) => field.name !== data1.name
                            )
                          );
                          setFiltersData(
                            Object.fromEntries(
                              Object.entries(filtersData).filter(([key]) => key !== data1.name)
                            )
                          ); 
                          // setFiltersData(
                          //   Object.keys(filtersData) &&
                          //     Object.keys(filtersData).length > 0 &&
                          //     Object.keys(filtersData).filter(
                          //       (field) => field !== data1.name
                          //     )
                          // );
                          addFilter({ [data1.name]: "0" });
                        }
                      }}
                    />

                    <p className="fw-bold">{data1.content}</p>
                  </div>

                  <div>
                    {/* Conditionally render the field based on checkedFields and its type */}
                    {checkedFields &&
                      checkedFields.length > 0 &&
                      checkedFields.some(
                        (field) => field.name === data1.name
                      ) && (
                        <>
                          {checkedFields &&
                            checkedFields.length > 0 &&
                            checkedFields.map((data, index) => (
                              <div key={index}>
                                {/* Render field based on type */}
                                {data.type === "text" &&
                                  data.name === data1.name && (
                                    <div className="my-2">
                                      <Select
                                        name={data.name}
                                        className="selectpicker w-100"
                                        aria-labelledby="dealership-name-quick"
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        isSearchable={false}
                                        options={
                                          data.name === "week"
                                            ? weekOptions
                                            : textOptions
                                        }
                                        onChange={(selectedOption) => {
                                          // Update the type in the object with the selected type
                                          setCheckedFields(
                                            checkedFields.map((field) =>
                                              field.name === data.name
                                                ? {
                                                    ...field,
                                                    filterType:
                                                      selectedOption.value,
                                                  }
                                                : field
                                            )
                                          );
                                          if (data.value) {
                                            const Data = {
                                              [data.name]: [
                                                data.value,
                                                selectedOption.value,
                                              ],
                                            };
                                            const filteredFields =
                                              checkedFields.filter(
                                                (field) =>
                                                  field?.value !== "" ||
                                                  field?.value.length > 0
                                              );

                                            // Create a new object from the filtered fields
                                            const filteredData =
                                              filteredFields.reduce(
                                                (acc, field) => {
                                                  acc[
                                                    field.type === "dropdown"
                                                      ? field.name + "_id"
                                                      : field.name
                                                  ] =
                                                    field.type === "text"
                                                      ? [
                                                          field.value,
                                                          field.filterType,
                                                        ]
                                                      : [field.value];
                                                  return acc;
                                                },
                                                {}
                                              );

                                            // Merge the filteredData object with the initial Data object
                                            const mergedData = {
                                              ...filteredData,
                                              ...Data,
                                            };
                                            if (filterOption) {
                                              mergedData.type = filterOption;
                                            }

                                            setFiltersData(mergedData);
                                          }
                                        }}
                                        styles={{
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            width: "100%",
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "white",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none",
                                            borderRadius: ".45rem",
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem",
                                            width: "100%",
                                          }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0",
                                            width: "100%",
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333",
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                      {data.filterType === "between" ? (
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                          <input
                                            type="text"
                                            name={data.name + "1"}
                                            className="form-control mt-2"
                                            onChange={(e) => {
                                              // Update the value in the object with the input text
                                              const { value } = e.target;

                                              // Update weekRange with the new value (either range1 or range2)
                                              setWeekRange((prevRange) => ({
                                                ...prevRange,
                                                range1: value,
                                              }));
                                              setCheckedFields(
                                                checkedFields.map((field) =>
                                                  field.name === data.name
                                                    ? {
                                                        ...field,
                                                        value: [
                                                          value, // Set range1 at index 0
                                                          field.value[1] || "", // Preserve range2 at index 1 or set to empty string
                                                        ],
                                                      }
                                                    : field
                                                )
                                              );

                                              if (data.filterType) {
                                                const Data = {
                                                  [data.name]: [
                                                    value,
                                                    data.filterType,
                                                    weekRange.range2,
                                                  ],
                                                };

                                                const filteredFields =
                                                  checkedFields.filter(
                                                    (field) =>
                                                      field?.value !== "" ||
                                                      field?.value.length > 0
                                                  );

                                                // Create a new object from the filtered fields
                                                const filteredData =
                                                  filteredFields.reduce(
                                                    (acc, field) => {
                                                      acc[
                                                        field.type ===
                                                        "dropdown"
                                                          ? field.name + "_id"
                                                          : field.name
                                                      ] =
                                                        field.type === "text"
                                                          ? [
                                                              field.value,
                                                              field.filterType,
                                                            ]
                                                          : [field.value];
                                                      return acc;
                                                    },
                                                    {}
                                                  );

                                                // Merge the filteredData object with the initial Data object
                                                const mergedData = {
                                                  ...filteredData,
                                                  ...Data,
                                                };
                                                if (filterOption) {
                                                  mergedData.type =
                                                    filterOption;
                                                }
                                                setFiltersData(mergedData);
                                              }
                                            }}
                                            style={{
                                              backgroundColor: isDarkMode
                                                ? "transparent"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "#000000",
                                              border: isDarkMode
                                                ? "1px solid #495057"
                                                : "1px solid #dee2e6",
                                            }}
                                          />
                                          <input
                                            type="text"
                                            name={data.name + "2"}
                                            className="form-control mt-2"
                                            onChange={(e) => {
                                              const { value } = e.target;

                                              // Update weekRange with the new value (either range1 or range2)
                                              setWeekRange((prevRange) => ({
                                                ...prevRange,
                                                range2: value,
                                              }));
                                              // Update the value in the object with the input text
                                              setCheckedFields(
                                                checkedFields.map((field) =>
                                                  field.name === data.name
                                                    ? {
                                                        ...field,
                                                        value: [
                                                          field.value[0] || "", // Preserve range1 at index 0 or set to empty string
                                                          value, // Set range2 at index 1
                                                        ],
                                                      }
                                                    : field
                                                )
                                              );

                                              if (data.filterType) {
                                                const Data = {
                                                  [data.name]: [
                                                    weekRange.range1,
                                                    data.filterType,
                                                    value,
                                                  ],
                                                };

                                                const filteredFields =
                                                  checkedFields.filter(
                                                    (field) =>
                                                      field?.value !== "" ||
                                                      field?.value.length > 0
                                                  );

                                                // Create a new object from the filtered fields
                                                const filteredData =
                                                  filteredFields.reduce(
                                                    (acc, field) => {
                                                      acc[
                                                        field.type ===
                                                        "dropdown"
                                                          ? field.name + "_id"
                                                          : field.name
                                                      ] =
                                                        field.type === "text"
                                                          ? [
                                                              field.value,
                                                              field.filterType,
                                                            ]
                                                          : [field.value];
                                                      return acc;
                                                    },
                                                    {}
                                                  );

                                                // Merge the filteredData object with the initial Data object
                                                const mergedData = {
                                                  ...filteredData,
                                                  ...Data,
                                                };
                                                if (filterOption) {
                                                  mergedData.type =
                                                    filterOption;
                                                }
                                                setFiltersData(mergedData);
                                              }
                                            }}
                                            style={{
                                              backgroundColor: isDarkMode
                                                ? "transparent"
                                                : "",
                                              color: isDarkMode
                                                ? "#ffffff"
                                                : "#000000",
                                              border: isDarkMode
                                                ? "1px solid #495057"
                                                : "1px solid #dee2e6",
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <input
                                          type="text"
                                          name={data.name}
                                          className="form-control mt-2"
                                          onChange={(e) => {
                                            // Update the value in the object with the input text
                                            setCheckedFields(
                                              checkedFields.map((field) =>
                                                field.name === data.name
                                                  ? {
                                                      ...field,
                                                      value: e.target.value,
                                                    }
                                                  : field
                                              )
                                            );

                                            if (data.filterType) {
                                              const Data = {
                                                [data.name]: [
                                                  e.target.value,
                                                  data.filterType,
                                                ],
                                              };
                                              const filteredFields =
                                                checkedFields.filter(
                                                  (field) =>
                                                    field?.value !== "" ||
                                                    field?.value.length > 0
                                                );

                                              // Create a new object from the filtered fields
                                              const filteredData =
                                                filteredFields.reduce(
                                                  (acc, field) => {
                                                    acc[
                                                      field.type === "dropdown"
                                                        ? field.name + "_id"
                                                        : field.name
                                                    ] =
                                                      field.type === "text"
                                                        ? [
                                                            field.value,
                                                            field.filterType,
                                                          ]
                                                        : [field.value];
                                                    return acc;
                                                  },
                                                  {}
                                                );

                                              // Merge the filteredData object with the initial Data object
                                              const mergedData = {
                                                ...filteredData,
                                                ...Data,
                                              };
                                              if (filterOption) {
                                                mergedData.type = filterOption;
                                              }
                                              setFiltersData(mergedData);
                                            }
                                          }}
                                          style={{
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#000000",
                                            border: isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}

                                {data.type === "date" &&
                                  data.name === data1.name && (
                                    <div className="mt-3">
                                      <input
                                        type="date"
                                        name={data.name}
                                        className={`form-control ${
                                          isDarkMode ? "custom-date" : ""
                                        }`}
                                        onChange={(e) => {
                                          // Update the value in the object with the input text
                                          setCheckedFields(
                                            checkedFields.map((field) =>
                                              field.name === data.name
                                                ? {
                                                    ...field,
                                                    value: e.target.value,
                                                  }
                                                : field
                                            )
                                          );

                                          const Data = {
                                            [data.name]: [e.target.value],
                                          };
                                          const filteredFields =
                                            checkedFields.filter(
                                              (field) =>
                                                field?.value !== "" ||
                                                field?.value.length > 0
                                            );

                                          // Create a new object from the filtered fields
                                          const filteredData =
                                            filteredFields.reduce(
                                              (acc, field) => {
                                                acc[
                                                  field.type === "dropdown"
                                                    ? field.name + "_id"
                                                    : field.name
                                                ] =
                                                  field.type === "text"
                                                    ? [
                                                        field.value,
                                                        field.filterType,
                                                      ]
                                                    : [field.value];
                                                return acc;
                                              },
                                              {}
                                            );

                                          // Merge the filteredData object with the initial Data object
                                          const mergedData = {
                                            ...filteredData,
                                            ...Data,
                                          };
                                          if (filterOption) {
                                            mergedData.type = filterOption;
                                          }
                                          setFiltersData(mergedData);
                                          // getFilteredEvents(mergedData);
                                        }}
                                        style={{
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "",
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#000000",
                                          border: isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6",
                                          marginTop: "-8px",
                                        }}
                                      />
                                    </div>
                                  )}
                                {data.type === "dropdown" &&
                                  data.name === data1.name && (
                                    <div
                                      className="mt-2"
                                      style={{ maxWidth: "" }}
                                    >
                                      <Select
                                        name={data.name}
                                        className="selectpicker w-100"
                                        aria-labelledby="dealership-name-quick"
                                        placeholder={
                                          translations["dropdown"].select[
                                            language
                                          ]
                                        }
                                        isMulti={true}
                                        isClearable={false}
                                        isSearchable={true}
                                        options={
                                          data.name === "dealership"
                                            ? dealerOptions
                                            : data.name === "project_manager"
                                            ? managers
                                            : data.name === "sales_rep"
                                            ? repOptions2
                                            : data.name === "package"
                                            ? packageOptions
                                            : animatorOptions
                                        }
                                        maxMenuHeight={"120px"} // Set a max height for the dropdown menu
                                        onChange={(selectedOption) => {
                                          const selectedIds =
                                            selectedOption.map(
                                              (data) => data.value
                                            );
                                          setCheckedFields(
                                            checkedFields.map((field) =>
                                              field.name === data.name
                                                ? {
                                                    ...field,
                                                    value: selectedIds,
                                                  }
                                                : field
                                            )
                                          );

                                          const Data = {
                                            [data.name + "_id"]: selectedIds,
                                          };
                                          const filteredFields =
                                            checkedFields.filter(
                                              (field) =>
                                                field?.value !== "" ||
                                                field?.value.length > 0
                                            );

                                          // Create a new object from the filtered fields
                                          const filteredData =
                                            filteredFields.reduce(
                                              (acc, field) => {
                                                acc[
                                                  field.type === "dropdown"
                                                    ? field.name + "_id"
                                                    : field.name
                                                ] =
                                                  field.type === "text"
                                                    ? [
                                                        field.value,
                                                        field.filterType,
                                                      ]
                                                    : [field.value];
                                                return acc;
                                              },
                                              {}
                                            );

                                          // Merge the filteredData object with the initial Data object
                                          const mergedData = {
                                            ...filteredData,
                                            ...Data,
                                          };
                                          if (filterOption) {
                                            mergedData.type = filterOption;
                                          }
                                          setFiltersData(mergedData);
                                          // getFilteredEvents(mergedData);
                                        }}
                                        styles={{
                                          input: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          control: (provided, state) => ({
                                            ...provided,
                                            width: "100%",
                                            backgroundColor: isDarkMode
                                              ? "transparent"
                                              : "white",
                                            border: state.isFocused
                                              ? "1px solid #80bdff"
                                              : isDarkMode
                                              ? "1px solid #495057"
                                              : "1px solid #dee2e6",
                                            boxShadow: state.isFocused
                                              ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                              : "none",
                                            borderRadius: ".45rem",
                                            maxHeight: "38px", // Constrain the height of the control
                                            overflowY: "hidden", // Hide overflow
                                          }),
                                          valueContainer: (provided) => ({
                                            ...provided,
                                            padding: "0.18rem 0.55rem",
                                            width: "100%",
                                            overflow: "hidden", // Hide overflow for the container
                                          }),
                                          multiValue: (provided) => ({
                                            ...provided,
                                            maxWidth: "calc(100% - 40px)", // Adjust as needed
                                            overflow: "hidden",
                                            textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                          }),
                                          multiValueLabel: (provided) => ({
                                            ...provided,
                                            whiteSpace: "nowrap", // Prevent text from wrapping
                                          }),
                                          // multiValueRemove: (provided) => ({
                                          //   ...provided,
                                          //   cursor: 'pointer',
                                          // }),
                                          indicatorSeparator: (provided) => ({
                                            ...provided,
                                            display: "none",
                                          }),
                                          dropdownIndicator: (provided) => ({
                                            ...provided,
                                            padding: "0.375rem 0.75rem",
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#868686",
                                          }),
                                          menu: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            margin: "0",
                                            zIndex: "999",
                                            width: "100%",
                                            maxHeight: "120px", // Set a max height for the dropdown menu
                                            overflowY: "auto", // Add vertical scrollbar if content overflows
                                          }),
                                          placeholder: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "#333333",
                                          }),
                                          singleValue: (provided) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                          }),
                                          option: (provided, state) => ({
                                            ...provided,
                                            color: isDarkMode
                                              ? "#ffffff"
                                              : "black",
                                            backgroundColor: state.isHovered
                                              ? "#80bdff"
                                              : isDarkMode
                                              ? "#1f2023"
                                              : "white",
                                            ":hover": {
                                              backgroundColor: "#34DBB4",
                                            },
                                          }),
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            ))}
                        </>
                      )}
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div
          className="d-flex mx-4 justify-content-between align-items-center pt-3"
          style={{
            borderTop: "1px solid #dee2e6",
          }}
        >
          <div
            className="d-flex justify-content-end align-items-center"
            style={
              {
                // borderTop: "1px solid #dee2e6",
              }
            }
          >
            {checkedFields && checkedFields.length > 0 && (
              <button
                className="btn btn-light d-flex align-items-center me-4 px-4 py-2"
                type="button"
                // disabled={
                //   checkedFields &&
                //   checkedFields.length > 0 &&
                //   checkedFields.some(
                //     (field) => field?.value === "" || field?.value?.length === 0
                //   )
                // }
                onClick={() => {
                  const clearAll = [];
                  addFilter(clearAll);
                  setCheckedFields([]);
                  setFiltersData([]);
                  setWeekRange({ range1: "", range2: "" });
                }}
                style={{ background: "", border: "none" }}
              >
                <i className="bi bi-x me-2"></i>
                {translations.commonWords.clearAll[language]}{" "}
              </button>
            )}
          </div>
          <button
            className="btn admin-btn text-black btn-primary  d-flex align-items-center px-5 py-2"
            type="button"
            disabled={
              checkedFields.length === 0 || // Disable if checkedFields is empty
              (checkedFields.length > 0 && // If checkedFields has data
                checkedFields.some(
                  (field) =>
                    field?.value === "" || // Check if any field value is empty
                    (Array.isArray(field?.value) && field.value.length === 0) // Check if field value is an empty array
                ))
            }
            onClick={() => {
              // if (
              //   checkedFields.filter(
              //     (field) => field?.value === "" || field?.value.length === 0
              //   )
              // ) {
              //   toast.error("Set Filters First");
              // }
              getFilteredEvents(filtersData);
              button.click();
            }}
          >
            {translations.commonWords.search[language]}{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;
