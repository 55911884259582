import React from "react";
import Chart from "react-apexcharts";

const AppointmentsPerHour = ({ isDarkMode, titleX, titleY, graphData }) => {
  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return `${label.substring(0, maxLength)}...`;
    }
    return label;
  };

  // const average =
  //   [
  //     {
  //       name: "Revenue",
  //       data:
  //         graphData && graphData.length > 0
  //           ? graphData.map((item) => {
  //               return toString(item.count);
  //             })
  //           : [],
  //     },
  //   ].reduce((a, b) => a + b, 0) /
  //   [
  //     {
  //       name: "Revenue",
  //       data:
  //         graphData && graphData.length > 0
  //           ? graphData.map((item) => {
  //               return item.count;
  //             })
  //           : [],
  //     },
  //   ].length;
  var options = {
    series: [
      {
        name: "Total Appointments",
        data:
          graphData && graphData.length > 0
            ? graphData.map((item) => {
                return item.count;
              })
            : [],
      },
    ],
    chart: {
      type: "bar",
      height: 450,
      stacked: false, // Change this to false to display bars in parallel

      toolbar: {
        show: false, // Hide the download button
      },
    },
    colors: ["#34DBB4"], // Set all bars to green
    dataLabels: {
      enabled: true,
      //   formatter: (value) => `CAD ${value?.toLocaleString()}`, // Format the label as CAD with commas
      offsetY: -25, // Position the labels slightly above the bars
      position: "top",
      style: {
        fontSize: "12px",
        colors: [isDarkMode ? "#ffffff" : "#373d3f"],
      },
    },

    xaxis: {
      categories:
        graphData && graphData.length > 0
          ? graphData.map((item) => {
              return item.hour;
            })
          : [],
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: isDarkMode
            ? graphData && graphData.length > 0
              ? graphData.map(() => {
                  return "#A5D6A7";
                })
              : [].map(() => {
                  return "#A5D6A7";
                })
            : [""],
        },
        formatter: (val) => truncateLabel(val, 18),
        // formatter: (val) => `${val} \nVendor`,
      },
      title: {
        // text: titleX || "Title",
        rotate: -90,
        margin: 10,
        padding: 10,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: isDarkMode ? "#ffffff" : "",
        },
      },
    },
    yaxis: {
      min: 0, // Set a minimum value slightly below the lowest bar to create space
      //   max: Math.max(...data.flatMap((series) => series?.data)) * 1.1,
      labels: {
        // formatter: (value) => `CAD ${value?.toLocaleString()}`,
        style: {
          fontSize: "12px",
          fontWeight: "bold",
          colors: [isDarkMode ? "#A5D6A7" : "#373d3f"],
        },
      },
      title: {
        // text: titleY || "Title",
        rotate: -90,
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "14px",
          fontWeight: "bold",
          color: isDarkMode ? "#ffffff" : "",
        },
      },
    },
    // legend: {
    //   position: "right", // Position the legend on the right
    //   horizontalAlign: "center",
    //   offsetY: 0,
    //   offsetX: -20,
    //   labels: {
    //     useSeriesColors: true,
    //   },
    //   markers: {
    //     width: 12,
    //     height: 12,
    //     radius: 0,
    //   },
    //   itemMargin: {
    //     vertical: 5,
    //   },

    //   onItemClick: {
    //     toggleDataSeries: true, // Enable checkbox-like behavior to toggle series
    //   },
    // },

    tooltip: {
      y: {
        // formatter: (value) => `CAD ${value?.toLocaleString()}`,
        formatter: function (val) {
          return val;
        },
      },
      theme: isDarkMode ? "dark" : "light", // Set tooltip theme
    },
    plotOptions: {
      bar: {
        columnWidth: "80%", // Adjust the width of the bars
        dataLabels: {
          position: "top", // This ensures that the label appears on top of the bar
        },
      },
    },
  };

  return (
    <div className="my-4 w-100" style={{ maxHeight: "450px" }}>
      <div className="row w-100">
        <div className="mixed-chart w-100">
          <Chart
            options={options}
            series={options.series}
            type="bar"
            width="100%"
            height="450"
          />
        </div>
      </div>
    </div>
  );
};

export default AppointmentsPerHour;
