import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Calendar,
  Views,
  DateLocalizer,
  momentLocalizer,
} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { ApiUrl } from "../../../ApiUrl";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import EditAppointmenModal from "./editAppointmenModal";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

function CalendarView2({
  isDarkMode,
  calendarData,
  setCalendarData,
  eventId,
  setIsLoading,
  setDisplayComponent,
}) {
  function getFormattedDate(appointment_date, appointment_time) {
    // console.log(
    //   "Received date:",
    //   appointment_date,
    //   "Received time:",
    //   appointment_time
    // );

    // Default time if appointment_time is missing or invalid
    const defaultTime = "09:00:00";
    const timeToUse =
      appointment_time && appointment_time.includes(":")
        ? appointment_time
        : defaultTime;

    // Parse the date and time components
    if (!appointment_date) {
      console.error("Missing appointment date");
      return null;
    }

    const [year, month, day] = appointment_date.split("-").map(Number);
    const [hours, minutes, seconds] = timeToUse.split(":").map(Number);

    // Construct startDate object
    const startDate = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds || 0
    );

    if (isNaN(startDate)) {
      // console.error("Invalid startDate generated");
      return null;
    }

    // console.log("Generated Start Date:", startDate);
    return startDate;
  }

  const { language } = useLanguage();

  // const events = [
  //   {
  //     id: 0,
  //     title: "Board meeting",
  //     start: new Date(2024, 10, 6, 0, 0, 0), // October is month 9 in JS
  //     end: new Date(2024, 10, 6, 0, 30, 0),
  //     resourceId: 1,
  //   },
  //   {
  //     id: 1,
  //     title: "MS training",
  //     // allDay: true,
  //     start: new Date(2024, 10, 6, 9, 0, 0),
  //     end: new Date(2024, 10, 6, 13, 30, 0),
  //     resourceId: 2,
  //   },
  //   {
  //     id: 2,
  //     title: "Team lead meeting",
  //     start: new Date(2024, 10, 5, 8, 30, 0),
  //     end: new Date(2024, 10, 5, 12, 30, 0),
  //     resourceId: 3,
  //   },
  //   {
  //     id: 11,
  //     title: "Birthday Party",
  //     start: new Date(2024, 10, 5, 21, 0, 0),
  //     end: new Date(2024, 10, 5, 23, 30, 0),
  //     resourceId: 4,
  //   },
  // ];

  // const resourceMap = [
  //   { resourceId: 1, resourceTitle: "Board room" },
  //   { resourceId: 2, resourceTitle: "Training room" },
  //   { resourceId: 3, resourceTitle: "Meeting room 1" },
  //   { resourceId: 4, resourceTitle: "Meeting room 2" },
  // ];

  const events =
    calendarData && calendarData.length > 0
      ? calendarData
          .filter((item) => item.appointments && item.appointments.length > 0) // Filter items with non-empty appointments array
          .flatMap((item) =>
            item.appointments.map((appointment, index) => ({
              id: appointment.id,
              title:
                appointment?.prospective?.first_name +
                " " +
                appointment?.prospective?.last_name,
              start: getFormattedDate(
                appointment.appointment_date,
                appointment.appointment_time
              ),
              end: getFormattedDate(
                appointment.appointment_end_date,
                appointment.appointment_end_time
              ),
              resourceId: appointment.appointment_rep_user_id,
            }))
          )
      : [];

  const resourceMap =
    calendarData && calendarData.length > 0
      ? calendarData.map((item) => {
          return { resourceId: item?.rep_id, resourceTitle: item?.rep_name };
        })
      : [];

  const [appointmentData, seAppointmentData] = useState("");
  const [myEvents, setMyEvents] = useState(events);
  const [currentView, setCurrentView] = useState(Views.DAY);
  const [currentDate, setCurrentDate] = useState(new Date());
  const token = localStorage.getItem("token");
  const [calendarKey, setCalendarKey] = useState(0);
  const [hoveredEventId, setHoveredEventId] = useState(null);

  const handleEventHover = (eventId) => {
    // console.log(calendarData);

    const findAppointmentWithRepData = (calendarData, eventId) => {
      for (const data of calendarData) {
        const appointment = data.appointments.find(
          (appointment) => appointment.id == eventId
        );
        if (appointment) {
          seAppointmentData({
            rep_id: data.rep_id,
            rep_name: data.rep_name,
            appointment: appointment,
          });
        }
      }
      return null; // Return null if no matching appointment is found
    };

    // Usage example
    const data = findAppointmentWithRepData(calendarData, eventId);
    if (appointmentData) {
      var createModal = new window.bootstrap.Modal(
        document.getElementById("editAppointmentModal")
      );
      createModal.show();
    }
  };

  const { defaultDate, views, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: ["day", "week", "month"],
      // scrollToTime: new Date(1972, 0, 1, 8),
    }),
    []
  );

  const moveEvent = useCallback(
    ({
      event,
      start,
      end,
      isAllDay: droppedOnAllDaySlot = false,
      resourceId,
    }) => {
      // const { allDay } = event;
      // if (!allDay && droppedOnAllDaySlot) {
      //   event.allDay = true;
      // }

      // console.log(event, start, end,resourceId);

      // setMyEvents((prevEvents) =>
      //   prevEvents.map((prevEvent) =>
      //     prevEvent?.id === event?.id
      //       ? { ...event, start, end, resourceId }
      //       : prevEvent
      //   )
      // );
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, resourceId }];
      });
      const appointment_date = format(start, "yyyy-MM-dd"); // "2024-11-06"
      const appointment_time = format(start, "HH:MM");
      // console.log(appointment_date, appointment_time);

      dragAppointment(
        event?.id,
        resourceId,
        appointment_date,
        appointment_time
      );
    },
    [setMyEvents]
  );

  useEffect(() => {
    // Update calendarKey to force re-render when `myEvents` updates
    setCalendarKey((prevKey) => prevKey + 1);
  }, [myEvents]);
  // console.log(myEvents);

  const handleViewChange = (view) => {
    setCurrentView(view); // Track current view to prevent switching
  };

  const handleNavigate = (date) => {
    setCurrentDate(date); // Set the new date when navigating
  };

  async function dragAppointment(
    id,
    appointment_rep_user_id,
    appointment_date,
    appointment_time
  ) {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/drag-appointment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          appointment_rep_user_id: appointment_rep_user_id,
          appointment_date: appointment_date,
          appointment_time: appointment_time,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        // const newData = data.private_events.data;
        // setDashboardData(data);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  }
  // console.log(myEvents);

  return (
    <div>
      <EditAppointmenModal
        isDarkMode={isDarkMode}
        appointmentData={appointmentData}
        seAppointmentData={seAppointmentData}
        eventId={eventId}
        setIsLoading={setIsLoading}
        appointments={myEvents}
        setAppointments={setMyEvents}
        setDisplayComponent={setDisplayComponent}
      />

      <div
        className=""
        style={{
          height: "calc(100vh - 270px)",
          overflow: "auto",
          width: "100%",
        }}
      >
        {/* {myEvents && myEvents.length > 0 ? ( */}
        <DnDCalendar
          key={calendarKey}
          defaultDate={currentDate}
          events={myEvents}
          localizer={localizer}
          resourceIdAccessor="resourceId"
          resources={resourceMap}
          resourceTitleAccessor="resourceTitle"
          // showMultiDayTimes
          step={15}
          views={views}
          view={currentView} // Maintain the current view state
          onView={handleViewChange} // Update view state on view change
          onEventDrop={moveEvent}
          onNavigate={handleNavigate} // Update date on navigate
          // min={new Date(2024, 1, 1, 0, 0, 0)}
          // max={new Date(2024, 1, 1, 23, 59, 59)}
          components={{
            event: (props) => (
              <CustomEvent
                {...props}
                hoveredEventId={hoveredEventId} // Pass hover state to child
                onHover={handleEventHover} // Pass hover handler to child
              />
            ),
          }}
        />
        {/* ) : (
        <h4>{translations.EventsPortal.noDataMessage[language]}</h4>
      )} */}
      </div>
    </div>
  );
}

function CustomEvent({ event, hoveredEventId, onHover }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    onHover(event.id); // Trigger the hover state change in parent
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    onHover(null); // Reset hover state when mouse leaves
  };

  return (
    <div
      style={{
        // backgroundColor: isHovered ? "#80bdff" : "", // Change color on hover
        cursor: "pointer",
        // padding: "5px",
        // borderRadius: "5px",
      }}
    >
      <p onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {event.title}
      </p>
    </div>
  );
}

export default CalendarView2;
